import { memo, useCallback, useRef } from 'react';
import { Box, Flex, Text, Tooltip, clsx, createStyles } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { FileTableRowProps } from './FileTableRow.types';
import { formatDate, parseDateOnly } from '@/shared/utils/date';
import { FileStatusBadge } from '@/shared/components/files/FileStatusBadge';
import { openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset } from '@/shared/states/filePreview';
import { FileRowNotes } from '../FileRowNotes';
import { singularOrPlural } from '@/shared/utils/string';
import { setSelectedClientFile, useClientFileIndexStore } from '@/pageAI/states/client/clientFileIndex.states';
import { posthog } from '@/shared/plugins/posthog';
import { isFileAssetUnrecognized } from '@/pageAI/services/files';

const useStyles = createStyles((theme) => ({
  tableRow: {
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  selected: {
    backgroundColor: theme.colors.gray[1],
  },
  tableRowCell: {
    '&:hover': {
      '.edit-button': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
}));

const FileTableRowBase = ({
  fileAsset,
  rowIndex,
  hiddenColumns,
  style,
  renderActionCell,
  onClick,
}: FileTableRowProps) => {
  const { classes } = useStyles();
  const isSelected = useClientFileIndexStore((state) => state.selectedFileMapping[fileAsset.id]);

  const trRef = useRef<HTMLTableRowElement>(null);

  const handleSelectFile = useCallback(
    (event: React.MouseEvent) => {
      openRightSidebar();
      setFilePreviewAsset(fileAsset);
      setSelectedClientFile(fileAsset.id);

      onClick?.(event, rowIndex, fileAsset);

      posthog.capture('[Files] Select file', {
        fileId: fileAsset.id,
        fileName: fileAsset.name,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
      });
    },
    [fileAsset, onClick, rowIndex],
  );

  return (
    <tr
      key={fileAsset.id}
      ref={trRef}
      className={clsx('file-row', ['row', rowIndex].join('-'), classes.tableRow, {
        [classes.selected]: isSelected,
      })}
      data-index={rowIndex}
      {...(isSelected ? { 'data-active': true } : {})}
      onClick={handleSelectFile}
      style={{
        userSelect: 'none',
        transition: 'all 0.1s',
        height: 40,
        cursor: 'pointer',
        ...style,
      }}
      data-testid={fileAsset.metadata?.pageAIMetadata?.indexNumber}
    >
      {!hiddenColumns?.includes('#') && (
        <td style={{ fontWeight: 500 }} data-testid="file-id">
          {fileAsset.index || rowIndex + 1}
        </td>
      )}

      {!hiddenColumns?.includes('Name') && (
        <td className={classes.tableRowCell} data-testid="file-name">
          <Box
            component="span"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 6,
              cursor: 'pointer',
              '.ck.ck-content': {
                border: 'none',
              },
            }}
          >
            {isFileAssetUnrecognized(fileAsset) ? (
              <Tooltip label="Unrecognized file type" withinPortal withArrow>
                <Flex align="center" justify="center" sx={(theme) => ({ color: theme.colors.orange[6] })}>
                  <IconAlertTriangle size={14} />
                </Flex>
              </Tooltip>
            ) : (
              <FileRowNotes fileAsset={fileAsset} />
            )}

            <Text
              component="span"
              className="file-name"
              fw={500}
              sx={{
                order: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {fileAsset.metadata?.pageAIMetadata?.vaFileTypeName || fileAsset.name}
            </Text>
          </Box>
        </td>
      )}

      {!hiddenColumns?.includes('Upload Date') && (
        <td>
          <Text component="span" color="dark">
            {fileAsset.createdAt ? formatDate(parseDateOnly(fileAsset.createdAt)) : '-'}
          </Text>
        </td>
      )}

      {!hiddenColumns?.includes('Received Date') && (
        <td>
          <Text component="span" color="dark">
            {fileAsset.fileReceiveDate ? formatDate(parseDateOnly(fileAsset.fileReceiveDate)) : '-'}
          </Text>
        </td>
      )}

      {!hiddenColumns?.includes('Size') && (
        <td>
          <Text fz="0.875rem" color="dark" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {fileAsset.metadata?.totalPages} {singularOrPlural('page', 'pages')(fileAsset.metadata?.totalPages)}
          </Text>
        </td>
      )}

      {!hiddenColumns?.includes('Status') && (
        <td>
          <FileStatusBadge file={fileAsset} />
        </td>
      )}

      {!hiddenColumns?.includes('') && (
        <td style={{ textAlign: 'right' }}>{renderActionCell?.(fileAsset, rowIndex)}</td>
      )}
    </tr>
  );
};

export const FileTableRow = memo(FileTableRowBase);
