import {
  isReviewingIncrementalChangesSelector,
  onlyReviewNewIncrementalChangesSelector,
  setOnlyReviewNewIncrementalChanges,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { Checkbox } from '@mantine/core';
import { memo } from 'react';

interface OnlyReviewNewIncrementalChangesToggleProps {}

const OnlyReviewNewIncrementalChangesToggleBase = (props: OnlyReviewNewIncrementalChangesToggleProps) => {
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);
  const onlyReviewNewChanges = useUnifiedStore(onlyReviewNewIncrementalChangesSelector);

  if (!isReviewing) return null;

  return (
    <Checkbox
      label="Show changes"
      size="xs"
      checked={onlyReviewNewChanges}
      onChange={(event) => setOnlyReviewNewIncrementalChanges(event.target.checked)}
      sx={{
        whiteSpace: 'nowrap',
      }}
    />
  );
};

export const OnlyReviewNewIncrementalChangesToggle = memo(OnlyReviewNewIncrementalChangesToggleBase);
