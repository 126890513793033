import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@mantine/core';

import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { ExportTimelineLayout } from '@/pageAI/components/timeline/export/ExportTimelineLayout';
import { useClientTimelineEvents } from '@/pageAI/hooks/clients/useClientTimelineEvents';

const ExportTimelinePageBase = () => {
  const { clientId } = useParams();
  const { client, isLoading: isLoadingClient, isError: isErrorClient } = useClient({ clientId });
  const {
    timelineEvents,
    isLoading: isLoadingTimelineEvents,
    isError: isTimelineEventsError,
  } = useClientTimelineEvents({ clientId });

  const isLoading = isLoadingClient || isLoadingTimelineEvents;
  const isError = isErrorClient || isTimelineEventsError;

  const eventIds = window.serverInjection?.timelineExport?.eventIds;
  const showBookmarkSigns = window.serverInjection?.timelineExport?.showBookmarkSigns;
  const showEventDetails = window.serverInjection?.timelineExport?.showEventDetails;
  const tab = window.serverInjection?.timelineExport?.tab;
  const sortOrder = window.serverInjection?.timelineExport?.sortOrder;
  const cFileIds = window.serverInjection?.timelineExport?.cFileIds;

  const timeline = useMemo(() => {
    if (!eventIds?.length) return timelineEvents;

    return timelineEvents.filter((event) => eventIds.includes(event.id));
  }, [eventIds, timelineEvents]);

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading timeline..."
        description="Please wait while Page retrieves the timeline."
        fullScreen
      />
    );

  if (!clientId || isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  return (
    <ExportTimelineLayout
      client={client}
      timeline={timeline}
      showBookmarkSigns={showBookmarkSigns}
      showEventDetails={showEventDetails}
      tab={tab}
      sortOrder={sortOrder}
      cFileIds={cFileIds}
    />
  );
};

export const ExportTimelinePage = memo(ExportTimelinePageBase);
