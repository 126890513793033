import { graphql } from '../gql';

export const exportJobFragment = graphql(/* GraphQL */ `
  fragment ExportJob_FullFields on ClientExportJob {
    id
    type
    status
    downloadUrl
    cFileDownloadUrl
    createdAt
    updatedAt
  }
`);

export const exportJobQuery = graphql(/* GraphQL */ `
  query ExportJob($id: IDV2!) {
    clientExportJob(id: $id) {
      ...ExportJob_FullFields
    }
  }
`);

export const exportJobsQuery = graphql(/* GraphQL */ `
  query ExportJobs($id: ID!, $type: ClientExportJobType!, $first: Int) {
    client(id: $id) {
      exportJobs(first: $first, type: $type) {
        nodes {
          ...ExportJob_FullFields
        }
      }
    }
  }
`);

export const exportConditionSummaryMutation = graphql(/* GraphQL */ `
  mutation ExportConditionSummary($input: ExportConditionSummaryInput!) {
    exportConditionSummary(input: $input) {
      exportJob {
        ...ExportJob_FullFields
      }
    }
  }
`);

export const exportTimelineMutation = graphql(/* GraphQL */ `
  mutation ExportTimeline($input: ExportTimelineInput!) {
    exportTimeline(input: $input) {
      exportJob {
        ...ExportJob_FullFields
      }
    }
  }
`);
