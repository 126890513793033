import { Alert, Button, Flex, Text } from '@mantine/core';
import { memo, useMemo } from 'react';

import {
  updatedConditionIdToReviewSelector,
  isReviewingIncrementalChangesSelector,
  previousConditionIdToReviewSelector,
  useUnifiedStore,
  selectedSimpleConditionSelector,
  previousConditionsSelector,
  conditionsSelector,
} from '@/pageAI/states/unified';
import { useUpdateConditionSummaryDelta } from '@/pageAI/hooks/conditions/reviewVersion/useUpdateConditionSummaryDelta';
import { useMergeConditions } from '@/pageAI/hooks/conditions/useMergeConditions';
import { useConditionSummaryDeltas } from '@/pageAI/hooks/conditions/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { isNewCondition } from '@/pageAI/services/medicalConditions/conditionVersionReview.services';
import { IncrementalSummaryDeltaStatus } from '@/pageAI/gql/graphql';

interface ConfirmConditionChangesAlertProps {}

const ConfirmConditionChangesAlertBase = (props: ConfirmConditionChangesAlertProps) => {
  const client = useSelectedClient(true);

  const { updateConditionDelta } = useUpdateConditionSummaryDelta();
  const { mergeConditions } = useMergeConditions();

  const { conditionSummaryDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const selectedSimpleCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);
  const previousConditionIdToReview = useUnifiedStore(previousConditionIdToReviewSelector);
  const updatedConditionIdToReview = useUnifiedStore(updatedConditionIdToReviewSelector);
  const conditions = useUnifiedStore(conditionsSelector);
  const previousConditions = useUnifiedStore(previousConditionsSelector);

  const previousCondition = useMemo(
    () =>
      isReviewing ? previousConditions?.find((condition) => condition.id === previousConditionIdToReview) : undefined,
    [previousConditions, previousConditionIdToReview, isReviewing],
  );

  const updatedCondition = useMemo(
    () => (isReviewing ? conditions.find((condition) => condition.id === updatedConditionIdToReview) : undefined),
    [conditions, updatedConditionIdToReview, isReviewing],
  );

  const equivalentDelta = useMemo(
    () =>
      isReviewing
        ? conditionSummaryDeltas?.find((delta) => delta.newCondition.id === updatedConditionIdToReview)
        : undefined,
    [conditionSummaryDeltas, updatedConditionIdToReview, isReviewing],
  );

  const isNewlyAddedCondition = useMemo(
    () => (updatedCondition && equivalentDelta ? isNewCondition(updatedCondition, equivalentDelta) : false),
    [updatedCondition, equivalentDelta],
  );

  const confirmFinishingReviewing = () => {
    if (!equivalentDelta) return;

    updateConditionDelta({
      deltaId: equivalentDelta.id,
      status: IncrementalSummaryDeltaStatus.Reviewed,
    });
  };

  const confirmMergeConditions = () => {
    if (!previousCondition || !updatedCondition || !previousConditionIdToReview || !updatedConditionIdToReview) return;

    confirmFinishingReviewing();

    mergeConditions({
      clientId: client.id,
      mergedConditionHeader: previousCondition?.headerCondition,
      sourceConditionIds: [previousConditionIdToReview, updatedConditionIdToReview],
    });
  };

  if (
    !isReviewing ||
    selectedSimpleCondition?.id !== updatedConditionIdToReview ||
    (!equivalentDelta && !isNewlyAddedCondition)
  )
    return null;

  if (isNewlyAddedCondition) {
    return (
      <Alert color="orange" title="Confirm newly added condition" variant="outline">
        {previousCondition ? (
          <Text>
            This is a newly added condition. You can either keep this new condition or merge it into the selected
            previous condition:
            <ul
              style={{
                margin: '4px 0 8px',
                paddingLeft: 24,
              }}
            >
              <li>
                <strong>Previous condition:</strong> {previousCondition?.headerCondition}
              </li>
            </ul>
          </Text>
        ) : (
          <Text mb={8}>
            This is a newly added condition. You can either keep this new condition or merge it into one of the previous
            conditions.
          </Text>
        )}

        <Flex align="center" gap="xs">
          {previousCondition && (
            <Button variant="outline" onClick={confirmMergeConditions}>
              Merge into previous condition
            </Button>
          )}

          <Button onClick={confirmFinishingReviewing}>Keep condition</Button>
        </Flex>
      </Alert>
    );
  }

  if (updatedCondition?.headerCondition === previousCondition?.headerCondition) {
    return (
      <Alert color="orange" title="Confirm new changes" variant="outline">
        <Text>
          By pressing the button below, you&apos;ll confirm that you have finished reviewing the changes for this
          condition.
        </Text>

        <Button mt="xs" onClick={confirmFinishingReviewing}>
          Confirm new changes
        </Button>
      </Alert>
    );
  }

  return (
    <Alert color="orange" title="Confirm merging conditions" variant="outline">
      {previousCondition ? (
        <Text>
          This condition has been updated from its previous version. You can choose to keep this condition as is or
          merge it into the previous condition:
          <ul
            style={{
              margin: '4px 0 8px',
              paddingLeft: 24,
            }}
          >
            <li>
              <strong>Previous condition:</strong> {previousCondition?.headerCondition}
            </li>
          </ul>
        </Text>
      ) : (
        <Text mb="xs">
          This condition has been updated from its previous version. You can choose to keep this condition as is or
          merge it into one of the previous conditions.
        </Text>
      )}

      <Flex align="center" gap="xs">
        {previousCondition ? <Button variant="outline">Merge into previous condition</Button> : null}

        <Button>Keep condition as is</Button>
      </Flex>
    </Alert>
  );
};

export const ConfirmConditionChangesAlert = memo(ConfirmConditionChangesAlertBase);
