export const API_URL = import.meta.env.VITE_APP_API_SERVER_URL || '';
export const SECONDARY_SERVER_URL = import.meta.env.VITE_APP_SECONDARY_SERVER_URL || 'http://localhost:3000';

export const CONTACT_EMAIL = import.meta.env.VITE_APP_CONTACT_EMAIL || 'support@pageai.co';

export const WEB_SOCKET_ENDPOINT = import.meta.env.VITE_APP_WS;

export const IS_BUILT_ENV = import.meta.env.PROD;
export const IS_STAGING = typeof API_URL === 'string' && API_URL.includes('.staging.');

export const POSTHOG_ID = import.meta.env.VITE_APP_POSTHOG_ID as string | undefined;
export const POSTHOG_API_HOST = import.meta.env.VITE_APP_POSTHOG_API_HOST || 'https://ph.pageai.co';

export const POST_LOG_OUT_REDIRECT_URL = import.meta.env.VITE_APP_POST_LOG_OUT_REDIRECT_URL as string | undefined;
export const AUTH0_DOMAIN = import.meta.env.VITE_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_APP_AUTH0_CLIENT_ID;
export const AUTH0_CALLBACK_URL = import.meta.env.VITE_APP_AUTH0_CALLBACK_URL;
export const AUTH0_AUDIENCE = import.meta.env.VITE_APP_AUTH0_AUDIENCE;
