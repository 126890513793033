import { memo } from 'react';
import { Flex, Paper, Text } from '@mantine/core';
import { IconArrowDownRight, IconArrowUpRight, TablerIconsProps } from '@tabler/icons-react';

interface StatsCardProps {
  title: React.ReactNode;
  value: React.ReactNode;
  description: React.ReactNode;
  diff?: number;
  Icon: React.ComponentType<TablerIconsProps>;
  dataTestId?: string;
}

const StatsCardBase = ({ title, value, description, Icon, diff, dataTestId }: StatsCardProps) => {
  const DiffIcon = typeof diff === 'number' ? (diff > 0 ? IconArrowUpRight : IconArrowDownRight) : null;

  return (
    <Paper withBorder p="md" radius={4} className="stats-card" data-testid={dataTestId}>
      <Flex align="center" justify="space-between" gap="xl">
        <Text size="xs" color="dimmed" fw={700} transform="uppercase" className="stats-card__title">
          {title}
        </Text>

        <Flex align="center" justify="center" sx={(theme) => ({ color: theme.colors.gray[4] })}>
          <Icon size={20} stroke={1.5} />
        </Flex>
      </Flex>

      <Flex align="flex-end" gap="xs" mt={24}>
        <Text
          fz="1.5rem"
          fw={700}
          color="dark"
          sx={{
            lineHeight: 1,
          }}
          className="stats-card__value"
        >
          {value}
        </Text>

        {typeof diff === 'number' && DiffIcon && (
          <Text c={diff > 0 ? 'teal' : 'red'} fz="sm" fw={500} sx={{}}>
            <span>{diff}%</span>
            <DiffIcon size="1rem" stroke={1.5} />
          </Text>
        )}
      </Flex>

      <Text fz="xs" color="dimmed" mt={4}>
        {description}
      </Text>
    </Paper>
  );
};

export const StatsCard = memo(StatsCardBase);
