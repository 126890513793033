import { Auth0Provider } from '@auth0/auth0-react';
import urlJoin from 'url-join';

import { AUTH0_AUDIENCE, AUTH0_CALLBACK_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/shared/config';

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithConfig: React.FC<Auth0ProviderWithConfigProps> = ({ children }) => {
  const redirectUri = AUTH0_CALLBACK_URL || urlJoin(window.location.origin, '/callback');

  return (
    <Auth0Provider domain={AUTH0_DOMAIN} clientId={AUTH0_CLIENT_ID} redirectUri={redirectUri} audience={AUTH0_AUDIENCE}>
      {children}
    </Auth0Provider>
  );
};
