import { memo } from 'react';
import { Flex, Tooltip } from '@mantine/core';

import { SimpleCondition } from '@/pageAI/@types/summaries';
import { ClaimStatus } from '@/pageAI/gql/graphql';
import { CONDITION_CLAIM_STATUS_COLOR_MAPPING } from '@/pageAI/services/medicalConditions';
import { useConditionClaimStatusFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionClaimStatusFeatureFlag';
import { ClaimStatusInfo } from '../ClaimStatusInfo';

interface ClaimStatusIndicatorProps {
  condition: SimpleCondition;
}

const ClaimStatusIndicatorBase = ({ condition }: ClaimStatusIndicatorProps) => {
  const { enabled } = useConditionClaimStatusFeatureFlag();
  const claimStatus = condition.claimStatus?.status || ClaimStatus.Unknown;

  if (!enabled) return null;

  return (
    <Tooltip
      label={
        claimStatus === ClaimStatus.Unknown ? (
          'Unknown'
        ) : (
          <ClaimStatusInfo
            claimStatus={condition.claimStatus?.status}
            sourceDocumentDate={condition.claimStatus?.sourceFileDate}
            dateToDisplay={condition.claimStatus?.dateToDisplay}
          />
        )
      }
      withinPortal
    >
      <Flex
        align="center"
        pl={1}
        pr={2}
        sx={(theme) => ({
          '.status-container': {
            width: 3,
            height: 14,
            backgroundColor: theme.colors[CONDITION_CLAIM_STATUS_COLOR_MAPPING[claimStatus]][6],
            overflow: 'hidden',
            padding: 0,
            borderRadius: 4,
            transition: 'all 0.15s ease-in-out',
            '.status-text': {
              visibility: 'hidden',
              transition: 'all 0.15s ease-in-out',
            },
          },
        })}
      >
        <Flex align="center" justify="center" className="status-container" />
      </Flex>
    </Tooltip>
  );
};

export const ClaimStatusIndicator = memo(ClaimStatusIndicatorBase);
