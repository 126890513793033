import { graphql } from '../gql';

export const conditionSummaryReferencedFileInfoFragment = graphql(/* GraphQL */ `
  fragment ConditionSummaryReferencedFileInfo_CommonFields on ConditionSummaryReferencedFileInfo {
    fileId
    fileReceiveDate
    eventDate
    summary
    reference
    isDuplicated
  }
`);

export const missingDocHintFragment = graphql(/* GraphQL */ `
  fragment MissingDocHint_CommonFields on MissingDocHint {
    fileId
    hintType
  }
`);

export const conditionCommonFieldsFragment = graphql(/* GraphQL */ `
  fragment ClientMedicalCondition_CommonFields on ClientMedicalCondition {
    id
    isMiscellaneous
    headerCondition
    subConditions
    conditionType
    conditionStatus
    conditionOrder
    conditionSummaryVersion
    isServiceConnected
    rating
    diagnosticCode
    claimStatus {
      fileReference
      isPendingConfirmation
      dateToDisplay
      sourceFileDate
      status
    }
    latestCodesheet {
      data {
        fileId
        fileReceiveDate
        eventDate
        summary
        reference
        isDuplicated
      }
      missingDocHints {
        fileId
        hintType
      }
    }
    generation
    createdAt
    updatedAt
  }
`);

export const conditionFullFieldsFragment = graphql(/* GraphQL */ `
  fragment ClientMedicalCondition_FullFields on ClientMedicalCondition {
    ...ClientMedicalCondition_CommonFields

    mergedConditions

    allCodesheets {
      data {
        ...ConditionSummaryReferencedFileInfo_CommonFields
      }
      missingDocHints {
        ...MissingDocHint_CommonFields
      }
    }

    proceduralHistory {
      vaForm218940s {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      applicableItf {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      filings {
        vaForm9 {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        disagreementNotices {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        initialClaims {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        requestsForHighLevelReview {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        supplementalClaims {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        bvaDisagreementNotices {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        vaForm21526b {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
        socStatements {
          data {
            ...ConditionSummaryReferencedFileInfo_CommonFields
          }
          missingDocHints {
            ...MissingDocHint_CommonFields
          }
        }
      }
      firstDecision {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      dateOfOriginalClaim {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      otherDecisions {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
    }

    medicalEvidence {
      vaExaminations {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      medicalOpinionsAndAddendums {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      inServiceRecords {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      postServiceRecords {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
    }

    otherEvidence {
      favorableFindings {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      layStatements {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
      additionalEvidence {
        data {
          ...ConditionSummaryReferencedFileInfo_CommonFields
        }
        missingDocHints {
          ...MissingDocHint_CommonFields
        }
      }
    }

    relatedTimelineEvents {
      id
      eventType
      summaries
      summariesTypes
      details
      detailsTypes
      date
      duplicated
      confidenceLevel
      reference
      referencedText
      verificationLevel
      bookmarked
      generation
      metadata {
        key
        value
      }
      viewerCanBookmark
      viewerCanComment
      viewerCanDelete
      viewerCanUnbookmark
      viewerCanUpdate
      viewerCanHide
      viewerCanUnhide
      createdAt
      updatedAt
    }
  }
`);

export const conditionsQuery = graphql(/* GraphQL */ `
  query ClientMedicalConditions($id: ID!, $conditionSummaryVersion: Int) {
    client(id: $id) {
      id
      medicalConditions(first: 9999, conditionSummaryVersion: $conditionSummaryVersion) {
        nodes {
          ...ClientMedicalCondition_CommonFields
        }
      }
    }
  }
`);

export const conditionQuery = graphql(/* GraphQL */ `
  query Condition($id: IDV2!) {
    clientMedicalCondition(id: $id) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const mergeConditionsMutation = graphql(/* GraphQL */ `
  mutation MergeConditions($input: MergeConditionsInput!) {
    mergeConditionsErrorCorrection(input: $input) {
      destCondition {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
      sourceConditions {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
    }
  }
`);

export const deleteMedicalConditionMutation = graphql(/* GraphQL */ `
  mutation DeleteMedicalCondition($input: DeleteMedicalConditionInput!) {
    deleteMedicalCondition(input: $input) {
      deletedMedicalConditionId
    }
  }
`);

export const addEventsToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddEventsToConditions($input: AddEventsToConditionsInput!) {
    addEventsToConditions(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const removeEventsFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveEventsFromCondition($input: RemoveEventsFromConditionInput!) {
    removeEventsFromCondition(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const addFilesToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddFilesToConditions($input: AddFilesToConditionsInput!) {
    addFilesToConditions(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const removeFilesFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveFilesFromCondition($input: RemoveFilesFromConditionInput!) {
    removeFilesFromCondition(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const conditionSearchTermsQuery = graphql(/* GraphQL */ `
  query ConditionSearchTerms($id: ID!) {
    client(id: $id) {
      searchTerms {
        code
        abbreviations
        terms
        termsFromDiagnostic
      }
    }
  }
`);

export const availableConditionKeywordsQuery = graphql(/* GraphQL */ `
  query AvailableConditionKeywords($keywords: [String!]!, $clientIds: [ID!]) {
    availableKeywords(keywords: $keywords, clientIds: $clientIds) {
      keyword
      score
    }
  }
`);

export const dismissHintsForConditionMutation = graphql(/* GraphQL */ `
  mutation DismissHintsForCondition($input: DismissHintsForConditionInput!) {
    dismissHintsForCondition(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const undoDismissHintsForConditionMutation = graphql(/* GraphQL */ `
  mutation UndoDismissHintsForCondition($input: UndoDismissHintsForConditionInput!) {
    undoDismissHintsForCondition(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const editMedicalConditionMutation = graphql(/* GraphQL */ `
  mutation EditMedicalCondition($input: EditMedicalConditionInput!) {
    editMedicalCondition(input: $input) {
      ...ClientMedicalCondition_FullFields
    }
  }
`);

export const conditionSummaryDeltaFragment = graphql(/* GraphQL */ `
  fragment ConditionSummaryDelta_FullFields on IncrementalSummaryDelta {
    id
    status
    newCondition {
      ...ClientMedicalCondition_CommonFields
    }
    newFileIds
    prevConditionHints {
      prevCondition {
        ...ClientMedicalCondition_CommonFields
      }
      hintType
      hintScore
    }
  }
`);

export const conditionSummaryDeltasQuery = graphql(/* GraphQL */ `
  query ConditionSummaryDeltas($id: ID!) {
    client(id: $id) {
      id
      conditionSummaryDeltas {
        ...ConditionSummaryDelta_FullFields
      }
    }
  }
`);

export const updateConditionSummaryDeltaMutation = graphql(/* GraphQL */ `
  mutation UpdateConditionSummaryDelta($input: UpdateDeltaInput!) {
    updateConditionSummaryDelta(input: $input) {
      ...ConditionSummaryDelta_FullFields
    }
  }
`);
