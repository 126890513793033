import JSZip from 'jszip';

export const unzip = async (zipFile: Blob, extensions = ['pdf']) => {
  const jsZip = new JSZip();

  // Load the zip file
  const zipContents = await jsZip.loadAsync(zipFile);
  const files: File[] = [];

  // Iterate through the files in the zip
  for (const fileName in zipContents.files) {
    const file = zipContents.files[fileName];

    // Check if the file is a PDF and not a folder
    if (!file.dir && extensions.some((ext) => fileName.endsWith(ext))) {
      if (fileName.startsWith('__MACOSX')) continue;

      const fileData = await file.async('blob'); // Get file content as Blob
      const cleanedFileName = fileName.split('/').pop() || fileName;
      const fileAsFile = new File([fileData], cleanedFileName, { type: fileData.type || 'application/pdf' });

      files.push(fileAsFile);
    }
  }

  return files;
};

export const unzipMultiple = async (zipFiles: Blob[], extensions = ['pdf']) => {
  if (zipFiles.length === 0) return [];

  const unzippedFiles = await Promise.all(zipFiles.map((zipFile) => unzip(zipFile, extensions)));

  return unzippedFiles.flat();
};

export const zip = async (files: File[], zipFileName: string) => {
  const jsZip = new JSZip();

  files.forEach((file) => {
    jsZip.file(file.name, file);
  });

  const zip = await jsZip.generateAsync({ type: 'blob' });

  const zipFile = new File([zip], zipFileName, { type: 'application/zip' });

  return zipFile;
};
