import { EventBus } from 'native-pubsub';

import { ConditionSummaryReferencedFileInfo, Note_FullFieldsFragment } from '@/pageAI/gql/graphql';
import { truthy } from '@/shared/utils/boolean';
import {
  CONDITION_FIELD_TEXT_MAPPING,
  getConditionName,
  getFormattedConditionSummaryEntryDate,
} from '../medicalConditions';
import { CaseTimelineEvent, MedicalConditionItem } from '@/pageAI/@types/summaries';
import { formatUSDate } from '@/shared/utils/date';
import { FileAsset } from '@/shared/@types';
import { posthog } from '@/shared/plugins/posthog';
import { Client } from '@/pageAI/@types';
import { getClientFiles } from '../clients';
import { extractFileInfoFromHref } from '../summaries';
import { extractHrefFromMarkdown } from '@/shared/utils/markdown';

export const startTakingNoteEventBus = new EventBus<{
  fileAsset?: FileAsset;
  snippet?: string | null;
  isMarkdown?: boolean;
  conditionEntryKey?: string;
  event?: CaseTimelineEvent;
  imageUrl?: string;
}>();

export const openNotepadEventBus = new EventBus<{
  searchValue?: string;
}>();

export const getNotesForFile = (notes: Note_FullFieldsFragment[], fileId: string) => {
  return notes.filter((note) => note.file?.id === fileId);
};

export const getAllAuthorsFromNotes = (notes: Note_FullFieldsFragment[]) => {
  const uniqueAuthorIds = new Set<string>();

  notes.forEach((note) => {
    uniqueAuthorIds.add(note.author?.id);
  });

  return [...uniqueAuthorIds]
    .map((uniqueAuthorId) => notes.find((note) => note.author?.id === uniqueAuthorId)?.author)
    .filter(truthy);
};

export const getNoteFileSnippet = (note: Note_FullFieldsFragment) => {
  return note.metadata?.find((metadata) => metadata.key === 'fileSnippet')?.value;
};

export const getNoteImageUrl = (note: Note_FullFieldsFragment) => {
  return note.metadata?.find((metadata) => metadata.key === 'imageUrl')?.value;
};

export const getNoteFilePageIndex = (note: Note_FullFieldsFragment) => {
  const pageIndexString = note.metadata?.find((metadata) => metadata.key === 'pageIndex')?.value;

  if (!pageIndexString) return;

  return Number(pageIndexString);
};

export const getNoteConditionEntryKey = (note: Note_FullFieldsFragment) => {
  return note.metadata?.find((metadata) => metadata.key === 'conditionEntryKey')?.value;
};

export const getNoteMetadataInfo = (note: Note_FullFieldsFragment) => {
  const snippet = getNoteFileSnippet(note);
  const imageUrl = getNoteImageUrl(note);
  const isMarkdown = note.metadata?.find((metadata) => metadata.key === 'isSnippetMarkdown')?.value === 'true';
  const conditionEntryKey = getNoteConditionEntryKey(note);

  return { snippet, imageUrl, isMarkdown, conditionEntryKey };
};

export const getConditionEntrySnippet = (entry: ConditionSummaryReferencedFileInfo, sectionField: string) => {
  return [
    `**${CONDITION_FIELD_TEXT_MAPPING[sectionField as keyof typeof CONDITION_FIELD_TEXT_MAPPING] || sectionField}**`,
    getFormattedConditionSummaryEntryDate(entry),
    entry.summary,
  ].join(': ');
};

export const getTimelineEventSnippet = (event: CaseTimelineEvent) => {
  return [`**${event.date}**`, event.eventSummary].join(': ');
};

export const getTimelineEventNoteDisplayText = (event: Pick<CaseTimelineEvent, 'date'>) => {
  return `Timeline Event - ${formatUSDate(event.date)}`;
};

export const takeNoteFromFile = ({
  client,
  fileAsset,
  imageUrl,
}: {
  client: Client;
  fileAsset: FileAsset;
  imageUrl?: string;
}) => {
  posthog.capture('[Notes] Take note from file', {
    clientId: client.id,
    clientFirstName: client.firstName,
    clientLastName: client.lastName,
    fileId: fileAsset.id,
    fileName: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
    fileIndex: fileAsset.metadata?.pageAIMetadata?.indexNumber,
  });

  startTakingNoteEventBus.publish({
    imageUrl,
  });
};

export const takeNoteFromTimelineEvent = ({ client, event }: { client: Client; event: CaseTimelineEvent }) => {
  posthog.capture('[Notes] Take note from timeline event', {
    clientId: client.id,
    clientFirstName: client.firstName,
    clientLastName: client.lastName,
    eventId: event.id,
    eventReference: event.reference,
  });

  startTakingNoteEventBus.publish({
    event,
    snippet: getTimelineEventSnippet(event),
    isMarkdown: true,
  });
};

export const takeNoteFromConditionEntry = ({
  client,
  condition,
  entryKey,
  entry,
  sectionField,
}: {
  client: Client;
  condition: MedicalConditionItem;
  entryKey: string;
  entry: ConditionSummaryReferencedFileInfo;
  sectionField: string;
}) => {
  posthog.capture('[Notes] Take note from condition summary', {
    clientId: client.id,
    clientFirstName: client.firstName,
    clientLastName: client.lastName,
    condition: getConditionName(condition),
    conditionEntryKey: entryKey,
  });

  const { fileAssets } = getClientFiles(client);
  const { fileAsset } = extractFileInfoFromHref(fileAssets, extractHrefFromMarkdown(entry.reference));

  startTakingNoteEventBus.publish({
    fileAsset,
    snippet: getConditionEntrySnippet(entry, sectionField),
    isMarkdown: true,
    conditionEntryKey: entryKey,
  });
};
