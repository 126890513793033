import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateConditionSummaryDeltaMutation } from '@/pageAI/api';
import { UpdateConditionSummaryDeltaMutation, UpdateDeltaInput } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useCallback } from 'react';
import { ConditionSummaryDelta } from '@/pageAI/@types';
import { ConditionSummaryDeltasQuery, queryMap } from '../../useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useUpdateConditionSummaryDelta = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const client = useSelectedClient(true);

  const { mutateAsync: editCondition, isLoading: isUpdating } = useMutation<
    UpdateConditionSummaryDeltaMutation,
    Error,
    UpdateDeltaInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(updateConditionSummaryDeltaMutation, { input });

      return responseBody;
    },
  });

  const updateConditionDeltaLocally = useCallback(
    (conditionDeltaId: string, fieldsToUpdate: Partial<ConditionSummaryDelta>) => {
      const queryKey = queryMap.client.conditionSummaryDeltas.queryKey(client.id);

      const previousConditionSummaryDeltasQuery = queryClient.getQueryData<ConditionSummaryDeltasQuery>(queryKey);

      const updatedConditionDeltas = previousConditionSummaryDeltasQuery?.map((conditionDelta) => {
        if (conditionDelta.id === conditionDeltaId) {
          return {
            ...conditionDelta,
            ...fieldsToUpdate,
          };
        }

        return conditionDelta;
      });

      queryClient.setQueryData(queryKey, updatedConditionDeltas);

      return () => queryClient.setQueryData(queryKey, previousConditionSummaryDeltasQuery);
    },
    [client, queryClient],
  );

  const updateConditionDelta = useCallback(
    async (input: UpdateDeltaInput) => {
      const reverse = updateConditionDeltaLocally(input.deltaId, { status: input.status });

      try {
        const responseBody = await editCondition(input);

        return responseBody;
      } catch (error) {
        reverse();

        notify('Error', 'Failed to confirm changes');
      }
    },
    [updateConditionDeltaLocally, editCondition, notify],
  );

  return { updateConditionDelta, isUpdating };
};
