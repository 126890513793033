import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { usePrevious } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';

import { CaseTimelineEvent, CaseTimelineTab, EventConfidenceLevel } from '@/pageAI/@types/summaries';
import { getBookmarkedTimelineEvents, getTimelineFilterValuesFromSearchParams } from '@/pageAI/services/caseTimeline';
import { useSelectableFileTypes } from '@/pageAI/hooks/caseTimeline/useSelectableFileTypes';
import { Client } from '@/pageAI/@types';
import { usePersistentFileTypeFilters } from '@/pageAI/hooks/summaries/usePersistentFileTypeFilters';
import { truthy } from '@/shared/utils/boolean';
import { SortOrder } from '@/shared/@types';
import { usePersistentFileGroupFilters } from '@/pageAI/hooks/summaries/usePersistentFileGroupFilters';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { getFileNamesFromFileGroups } from '@/pageAI/services/files';
import { useDisplayConditionRelatedEvents } from '@/pageAI/hooks/caseTimeline/useDisplayConditionRelatedEvents';

const getTimelineEventMapping = (timelineEvents: CaseTimelineEvent[]) => {
  return timelineEvents.reduce((acc, event) => {
    acc[event.id] = event;

    return acc;
  }, {} as Record<string, CaseTimelineEvent>);
};

export const useDelayedTimelineEvents = (timelineEvents: CaseTimelineEvent[], tab: CaseTimelineTab) => {
  const previousTab = usePrevious(tab);
  const [delayedTimelineEvents, setDelayedTimelineEvents] = useState<CaseTimelineEvent[]>(timelineEvents);

  useLayoutEffect(() => {
    if (tab === previousTab) {
      return setDelayedTimelineEvents((currentEvents) => {
        const newTimelineEventMapping = getTimelineEventMapping(timelineEvents);

        return currentEvents.map((currentEvent) => {
          const newTimelineEvent = newTimelineEventMapping[currentEvent.id];

          if (!newTimelineEvent) return currentEvent;

          return newTimelineEvent;
        });
      });
    }

    setDelayedTimelineEvents(tab === 'All' ? timelineEvents : getBookmarkedTimelineEvents(timelineEvents));
  }, [timelineEvents, tab, previousTab]);

  return { delayedTimelineEvents };
};

export const useScrollBorder = (shouldReset: boolean) => {
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    const caseTimelineScrollAreaElement = document.querySelector(
      '#case-timeline-scroll-area .ghost-ScrollArea-viewport',
    ) as HTMLDivElement | undefined;

    if (!caseTimelineScrollAreaElement) return;

    const handleScroll = (event: Event) => {
      const targetElement = event.target as HTMLDivElement;
      const { scrollTop } = targetElement;

      if (scrollTop > 0 && !showBorder) setShowBorder(true);
      else if (scrollTop === 0 && showBorder) setShowBorder(false);
    };

    caseTimelineScrollAreaElement.addEventListener('scroll', handleScroll);

    return () => {
      caseTimelineScrollAreaElement.removeEventListener('scroll', handleScroll);
    };
  }, [showBorder, shouldReset]);

  return [showBorder, setShowBorder] as const;
};

export const useFileGroupsFilter = (client: Client, initialValues?: string[]) => {
  const [persistentFileGroupFilters] = usePersistentFileGroupFilters(client.id);

  const [fileGroups, setFileGroups] = useState<string[] | null>(() => {
    if (initialValues && initialValues.length > 0) return initialValues;

    return persistentFileGroupFilters;
  });

  return [fileGroups, setFileGroups] as const;
};

export const useFileTypesFilter = (
  client: Client,
  caseTimeline: CaseTimelineEvent[],
  fileGroups: string[] | null,
  initialValues?: string[],
) => {
  const { fileGroupsMapping } = useFileGroups();
  const [persistentFileTypeFilters] = usePersistentFileTypeFilters(client.id);
  const { selectableFileTypes } = useSelectableFileTypes({ client, events: caseTimeline });

  const [fileTypes, setFileTypes] = useState<string[] | null>(() => {
    if (fileGroups?.length) {
      return getFileNamesFromFileGroups((fileGroups || []).map((value) => fileGroupsMapping[value]).filter(truthy));
    }

    if (initialValues && initialValues.length > 0) return initialValues;

    return persistentFileTypeFilters.filter((fileType) => selectableFileTypes.includes(fileType));
  });

  return [fileTypes, setFileTypes] as const;
};

export const useTimelineFilters = (client: Client, caseTimeline: CaseTimelineEvent[]) => {
  const [searchParams] = useSearchParams();

  const [initialValues] = useState(() => getTimelineFilterValuesFromSearchParams(searchParams));

  const [year, setYear] = useState<string | undefined>(initialValues.year);
  const [eventTypes, setEventTypes] = useState<string[]>(initialValues.eventTypes);
  const [fileGroups, setFileGroups] = useFileGroupsFilter(client, initialValues.fileGroups);
  const [fileTypes, setFileTypes] = useFileTypesFilter(
    client,
    caseTimeline,
    initialValues.fileGroups,
    initialValues.fileTypes,
  );
  const [fromDate, setFromDate] = useState<Date | undefined>(initialValues.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(initialValues.toDate);
  const [confidenceLevels, setConfidenceLevels] = useState<EventConfidenceLevel[]>(initialValues.confidenceLevels);
  const [searchKeywords, setSearchKeywords] = useState(initialValues.searchKeywords);
  const [matchWholeWords, setMatchWholeWords] = useState(initialValues.matchWholeWords);
  const [matchWholePhrase, setMatchWholePhrase] = useState(initialValues.matchWholePhrase);
  const [displayOnlyConditionEvents, setDisplayOnlyConditionEvents] = useDisplayConditionRelatedEvents(
    initialValues.displayOnlyConditionEvents,
  );
  const [displayOnlyAdditionalEvents, setDisplayOnlyAdditionalEvents] = useState(
    initialValues.displayOnlyAdditionalEvents,
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const splitKeywords = useMemo(
    () =>
      searchKeywords
        .split(' ')
        .map((keyword) => keyword.trim())
        .filter(truthy),
    [searchKeywords],
  );

  return {
    eventTypes,
    setEventTypes,
    fileGroups,
    setFileGroups,
    fileTypes,
    setFileTypes,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    confidenceLevels,
    setConfidenceLevels,
    searchKeywords,
    setSearchKeywords,
    matchWholeWords,
    setMatchWholeWords,
    matchWholePhrase,
    setMatchWholePhrase,
    splitKeywords,
    displayOnlyAdditionalEvents,
    setDisplayOnlyAdditionalEvents,
    displayOnlyConditionEvents,
    setDisplayOnlyConditionEvents,
    year,
    setYear,
    sortOrder,
    setSortOrder,
  };
};
