import { memo, useMemo } from 'react';
import { Alert, Flex } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { Client } from '@/pageAI/@types';
import { filterUnrecognizedFileAssets } from '@/pageAI/services/files';
import { getClientFiles } from '@/pageAI/services/clients';
import { singularOrPlural } from '@/shared/utils/string';

interface FileClassificationQuickAlertProps {
  client: Client;
}

const FileClassificationQuickAlertBase = ({ client }: FileClassificationQuickAlertProps) => {
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const unrecognizedFileAssets = useMemo(() => filterUnrecognizedFileAssets(fileAssets), [fileAssets]);

  if (!unrecognizedFileAssets.length) return null;

  return (
    <Alert
      mt={4}
      title={
        <Flex align="center" gap={6}>
          <IconAlertTriangle size="1rem" />
          There are unrecognized file types!
        </Flex>
      }
      color="orange"
      variant="outline"
    >
      Your client contains {unrecognizedFileAssets.length} unrecognized file{' '}
      {singularOrPlural('type', 'types')(unrecognizedFileAssets.length)} associated with this client. Please review to
      ensure your summaries will be of the highest possible quality.
    </Alert>
  );
};

export const FileClassificationQuickAlert = memo(FileClassificationQuickAlertBase);
