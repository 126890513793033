import { memo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ActionIcon, Box, Button, Flex, Modal, Switch, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload, IconInfoCircle } from '@tabler/icons-react';

import { ExportTimelineButtonProps } from './ExportTimelineButton.types';
import { useLayoutStore } from '@/shared/states/layout';
import { CaseTimelineTabs } from '../../CaseTimelineTabs';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';
import { setIsHrefActive, useSummaryFileReferenceBadgeState } from '../../../summaries/SummaryFileReferenceBadge';
import { useExportTimeline } from '@/pageAI/hooks/export/useExportTimeline';
import { LastExportJob } from '@/pageAI/components/export/LastExportJob';
import { ClientExportJobType } from '@/pageAI/gql/graphql';

const ExportTimelineButtonBase = ({ client, filteredTimeline, sortOrder }: ExportTimelineButtonProps) => {
  const isPrinting = useLayoutStore((state) => state.isPrinting);
  const isHrefActive = useSummaryFileReferenceBadgeState((state) => state.isHrefActive);
  const { exportTimeline } = useExportTimeline();

  const [opened, { open, close }] = useDisclosure();
  const [tab, setTab] = useState<CaseTimelineTab>('All');
  const [useCurrentFilters, setUseCurrentFilters] = useState(true);
  const [showBookmarkSigns, setShowBookmarkSigns] = useState(true);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const handleExportWithMergedFile = async () => {
    handleExport(true);
  };

  const handleExport = (withCFile = false) => {
    const isHrefActive = useSummaryFileReferenceBadgeState.getState().isHrefActive;

    exportTimeline({
      client,
      withCFile,
      eventIds: useCurrentFilters ? filteredTimeline.map((event) => event.id) : undefined,
      showBookmarkSigns,
      showEventDetails,
      showReferencesWithLinks: !withCFile && isHrefActive,
      tab: useCurrentFilters ? undefined : tab,
      sortOrder,
    });

    close();
  };

  const renderModalContent = () => {
    return (
      <Flex direction="column" gap="md">
        <LastExportJob client={client} type={ClientExportJobType.Timeline} />

        <Flex direction="column">
          <Text fz="0.875rem" fw={500} color="dark" mb={4}>
            Timeline filters
          </Text>

          <Flex direction="column" gap="xs">
            <Switch
              checked={useCurrentFilters}
              onChange={(event) => setUseCurrentFilters(event.currentTarget.checked)}
              label={
                <Flex align="center" gap={4}>
                  <Text>Use current filters</Text>

                  <Tooltip label="Use the filters that are currently applied to the timeline." withinPortal withArrow>
                    <ActionIcon size="xs">
                      <IconInfoCircle size={14} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              }
            />

            <Box>
              <CaseTimelineTabs initialValue={tab} onChange={setTab} disabled={useCurrentFilters} />
            </Box>
          </Flex>

          <Text fz="0.875rem" fw={500} color="dark" mt="xs" mb={4}>
            Export settings
          </Text>

          <Flex direction="column" gap="xs">
            <Switch
              checked={showBookmarkSigns}
              onChange={(event) => setShowBookmarkSigns(event.currentTarget.checked)}
              label="Show bookmark signs"
            />

            <Switch
              checked={showEventDetails}
              onChange={(event) => setShowEventDetails(event.currentTarget.checked)}
              label="Show event details"
            />

            <Switch
              checked={isHrefActive}
              onChange={(event) => setIsHrefActive(event.currentTarget.checked)}
              label="Show references with links"
            />
          </Flex>
        </Flex>

        <Flex align="center" justify="end" gap="xs">
          <Button onClick={close} color="gray.8" variant="subtle">
            Close
          </Button>

          <Button variant="outline" onClick={handleExportWithMergedFile}>
            Export with merged file
          </Button>

          <Button color="red" onClick={() => handleExport()}>
            Export
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {createPortal(
        <Tooltip label="Export timeline" withinPortal withArrow>
          <ActionIcon
            onClick={open}
            color="gray.6"
            sx={{ order: 2 }}
            id="export-timeline-button"
            data-testid="export-timeline"
          >
            <IconDownload size={16} />
          </ActionIcon>
        </Tooltip>,
        document.querySelector('#page-ai-header-suffix-section') || document.body,
      )}

      <Modal
        title="Export timeline to PDF"
        opened={opened}
        onClose={close}
        fullScreen={isPrinting}
        size={640}
        sx={{
          ...(isPrinting
            ? {
                display: 'none',
              }
            : {}),
        }}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

export const ExportTimelineButton = memo(ExportTimelineButtonBase);
