import { useCallback, useLayoutEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { UseClientMedicalConditionsProps } from './useClientMedicalConditions.types';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import {
  normalizeSimpleCondition,
  sortConditionsAlphebetically,
  sortConditionsByClaimStatus,
  sortConditionsByRating,
} from '@/pageAI/services/medicalConditions';
import {
  setConditions,
  setDefaultCondition,
  setPreviousConditions,
  toggleConditionSidebar,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { ConditionStatus, SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { truthy } from '@/shared/utils/boolean';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { useSelectedClient } from '../useSelectedClient';
import { conditionsQuery } from '@/pageAI/api/conditions';
import { useConditionClaimStatusFeatureFlag } from '../../featureFlags/useConditionClaimStatusFeatureFlag';
import { useIncrementalSummaryFeatureFlag } from '../../featureFlags/useIncrementalSummaryFeatureFlag';

interface FetchMedicalConditionsProps {
  request: GraphQLRequestFunction;
  clientId: string | undefined;
  version?: number;
  conditionClaimStatusEnabled?: boolean;
}

const fetchMedicalConditions = async ({
  request,
  clientId,
  version,
  conditionClaimStatusEnabled = false,
}: FetchMedicalConditionsProps) => {
  if (typeof clientId !== 'string') return Promise.resolve(null);

  const responseBody = await request(conditionsQuery, { id: clientId, conditionSummaryVersion: version });

  const normalizeConditions = (rawConditions: typeof responseBody.client.medicalConditions.nodes) => {
    let conditions =
      rawConditions?.filter(truthy).filter((condition) => condition.conditionStatus !== ConditionStatus.UpdateFailed) ||
      [];

    conditions = conditionClaimStatusEnabled
      ? sortConditionsByRating(sortConditionsAlphebetically(conditions))
      : sortConditionsByClaimStatus(sortConditionsAlphebetically(conditions));

    return conditions.map(normalizeSimpleCondition) || [];
  };

  const conditions = normalizeConditions(responseBody.client.medicalConditions.nodes);

  return conditions;
};

export const queryMap = {
  client: {
    medicalConditions: {
      queryKey: (clientId: string | undefined, version?: number) => ['client', clientId, 'medicalConditions', version],
      queryFn: fetchMedicalConditions,
    },
  },
};

export type ClientConditionsQuery = Awaited<ReturnType<typeof queryMap.client.medicalConditions.queryFn>>;

export const useClientMedicalConditions = ({ clientId, autoSelect = true }: UseClientMedicalConditionsProps) => {
  const { request } = useGraphQLRequest();
  const client = useSelectedClient(true);
  const { enabled, isLoading: isLoadingFeatureFlag } = useConditionClaimStatusFeatureFlag();

  const initConditionsState = useCallback(
    (conditions?: SimpleCondition[]) => {
      if (!conditions?.length || client.summaryByConditionStatus !== SummaryByConditionStatus.Generated) return;

      toggleConditionSidebar(true);
      setConditions(conditions);

      if (autoSelect) {
        setDefaultCondition();
      }
    },
    [autoSelect, client.summaryByConditionStatus],
  );

  const { data, isLoading, isError } = useQuery(
    queryMap.client.medicalConditions.queryKey(clientId),
    () => queryMap.client.medicalConditions.queryFn({ request, clientId, conditionClaimStatusEnabled: enabled }),
    {
      onSuccess: initConditionsState,
    },
  );

  useLayoutEffect(() => {
    if (!data?.length || isLoadingFeatureFlag || useUnifiedStore.getState().conditions.length) return;

    initConditionsState(data);
  }, [initConditionsState, data, isLoadingFeatureFlag]);

  return {
    medicalConditions: data || [],
    isLoading,
    isError,
  };
};

interface UseConditionsWithVersionProps {
  clientId: string;
  version: number;
}

export const useConditionsWithVersion = ({ clientId, version }: UseConditionsWithVersionProps) => {
  const { request } = useGraphQLRequest();
  const { enabled: conditionClaimStatusEnabled } = useConditionClaimStatusFeatureFlag();
  const { enabled: incrementalSummaryEnabled } = useIncrementalSummaryFeatureFlag();

  const initConditionsState = useCallback((conditions?: SimpleCondition[]) => {
    if (!conditions?.length) return;

    setPreviousConditions(conditions);
  }, []);

  const { data, isLoading, isError } = useQuery(
    queryMap.client.medicalConditions.queryKey(clientId, version),
    () =>
      incrementalSummaryEnabled
        ? queryMap.client.medicalConditions.queryFn({
            request,
            clientId,
            conditionClaimStatusEnabled,
            version,
          })
        : Promise.resolve(null),
    {
      onSuccess: initConditionsState,
    },
  );

  useLayoutEffect(() => {
    if (!data?.length || useUnifiedStore.getState().summaryTabState.previousConditions?.length) return;

    initConditionsState(data);
  }, [initConditionsState, data]);

  return {
    data,
    isLoading,
    isError,
  };
};
