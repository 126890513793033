import React, { memo, useCallback, useMemo } from 'react';
import { Accordion, Alert, Anchor, Button, Flex, ScrollArea, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertTriangle, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { Client } from '@/pageAI/@types';
import { getClientFiles } from '@/pageAI/services/clients';
import { filterUnreadableFileAssets, getFileAssetWithExtractedInfo } from '@/pageAI/services/files';
import { AssetTable } from '../../AssetTable';
import { AssetItem, AssetType } from '@/shared/@types';
import { AssetItemActions } from '@/shared/components/files/actions/AssetItemActions';
import { singularOrPlural } from '@/shared/utils/string';

export interface FileUnreadableAlertProps {
  client: Client;
}

const FileUnreadableAlertBase = ({ client }: FileUnreadableAlertProps) => {
  const queryClient = useQueryClient();
  const [opened, { open, toggle }] = useDisclosure();

  const { fileAssets, mainFileCollectionAsset } = useMemo(() => getClientFiles(client), [client]);
  const unreadableFiles = useMemo(() => filterUnreadableFileAssets(fileAssets), [fileAssets]);
  const filesToReview = useMemo(() => unreadableFiles.map(getFileAssetWithExtractedInfo), [unreadableFiles]);

  const handleReview = (event: React.MouseEvent) => {
    event.preventDefault();

    toggle();
  };

  const refetchClient = useCallback(async () => {
    await queryClient.refetchQueries(['client', client.id]);
  }, [client.id, queryClient]);

  const renderFileActions = useCallback(
    (assetItem: AssetItem, rowIndex: number) => {
      if (assetItem.type !== AssetType.FILE) return null;

      return (
        <AssetItemActions
          asset={assetItem}
          collectionId={mainFileCollectionAsset.id}
          hideDeleteButton={!assetItem.viewerCanDelete}
          canShare={false}
          onDeleted={refetchClient}
        />
      );
    },
    [mainFileCollectionAsset.id, refetchClient],
  );

  if (!unreadableFiles.length) return null;

  return (
    <Alert variant="light" color="orange" sx={(theme) => ({ border: `1px solid ${theme.colors.orange[5]}` })} mb={-12}>
      <Flex direction="column">
        <Flex justify="space-between">
          <Flex align="center" gap={6}>
            <Flex
              align="center"
              justify="center"
              sx={(theme) => ({
                color: theme.colors.orange[5],
              })}
            >
              <IconAlertTriangle size={20} />
            </Flex>

            <Text fz="0.875rem" color="dark">
              You have {unreadableFiles.length} unreadable {singularOrPlural('file', 'files')(unreadableFiles.length)} –
              &nbsp;
              <Anchor sx={{ textDecoration: 'underline' }} onClick={open}>
                please review
              </Anchor>
              &nbsp;{singularOrPlural('it', 'them')(unreadableFiles.length)}.
            </Text>
          </Flex>

          <Button
            variant="light"
            color="orange"
            size="xs"
            onClick={handleReview}
            sx={{
              height: 20,
              padding: '0 4px',
              '.ghost-Button-rightIcon': {
                marginLeft: 4,
              },
            }}
            rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          >
            Review
          </Button>
        </Flex>

        <Accordion
          value={opened ? 'default' : undefined}
          sx={{
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Item value="default" p={0} sx={{ border: 'none' }}>
            <Accordion.Panel p={0}>
              <Flex direction="column" gap={4}>
                <ScrollArea.Autosize mah={396} mt="md">
                  <Flex
                    pb="xs"
                    sx={(theme) => ({
                      '> div': {
                        width: '100%',
                      },
                      '.asset-table': {
                        background: theme.white,
                      },
                    })}
                  >
                    <AssetTable
                      fileAssets={filesToReview}
                      renderActionCell={renderFileActions}
                      thrStyle={{
                        position: 'relative',
                        top: 'unset ',
                      }}
                    />
                  </Flex>
                </ScrollArea.Autosize>
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
    </Alert>
  );
};

export const FileUnreadableAlert = memo(FileUnreadableAlertBase);
