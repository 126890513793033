import { memo } from 'react';

import { FileNotes } from '../../notes/FileNotes';
import { FileAsset } from '@/shared/@types';

interface FileRowNotesProps {
  fileAsset: FileAsset;
}

const FileRowNotesBase = ({ fileAsset }: FileRowNotesProps) => {
  return (
    <FileNotes
      fileAsset={fileAsset}
      canTakeNote={false}
      sx={{
        position: 'relative',
        top: 'unset',
        right: 'unset',
        order: 2,
      }}
      size={16}
    />
  );
};

export const FileRowNotes = memo(FileRowNotesBase);
