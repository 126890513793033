import { memo, useCallback, useEffect, useMemo } from 'react';
import { ActionIcon, Box, Flex, Loader, ScrollArea, Tabs, Text, Tooltip, Transition } from '@mantine/core';
import { IconBan, IconChevronDown, IconChevronUp, IconInfoCircle } from '@tabler/icons-react';

import { ConditionSearchTermChips } from '../ConditionSearchTermChips';
import {
  selectedSimpleConditionSelector,
  selectedTermCategorySelector,
  selectedTermsSelector,
  selectTermCategory,
  setSelectedTerms,
  setShowSearchTermsWithNoResults,
  showMedicalTermSearchSelector,
  showSearchTermsWithNoResultsSelector,
  toggleShowMedicalTermSearch,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { confirmContentSearchEventBus } from '../../clients/ClientFileSearchPanel/ClientFileSearchPanel.utils';
import {
  CONDITION_TERM_CATEGORY_LABEL_MAPPING,
  ConditionTermCategory,
  useConditionSearchTerms,
} from '@/pageAI/hooks/conditions/useConditionSearchTerms';
import {
  conditionTermStatusSelector,
  getQueryFromTerms,
  getTermsFromQuery,
  useTermSearchResultsState,
} from '@/pageAI/services/conditionSearchTerms';
import { FetchStatus } from '@/shared/@types';
import { keys } from '@/shared/utils/object';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';
import { singularOrPlural } from '@/shared/utils/string';
import { ConditionSearchTermHint } from '../ConditionSearchTermHint';

interface ConditionSearchTermContainerProps {
  alwaysShow?: boolean;
  mah?: number;
}

const ConditionSearchTermContainerBase = ({ alwaysShow = false, mah = 136 }: ConditionSearchTermContainerProps) => {
  const { getConditionSearchTerms, isLoading: isLoadingTerms, isError } = useConditionSearchTerms();

  const selectedCategory = useUnifiedStore(selectedTermCategorySelector);
  const selectedCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const opened = useUnifiedStore(showMedicalTermSearchSelector);
  const terms = useUnifiedStore(selectedTermsSelector);
  const showTermsWithNoResults = useUnifiedStore(showSearchTermsWithNoResultsSelector);
  const scoreResultByTerm = useTermSearchResultsState((state) => state.scoreByTerm);
  const termFetchStatus = useTermSearchResultsState(conditionTermStatusSelector);

  const isLoading = isLoadingTerms || termFetchStatus === FetchStatus.LOADING;

  const handleSelect = useCallback((newTerms: string[]) => {
    setSelectedTerms(newTerms);

    const newQuery = getQueryFromTerms(newTerms);
    confirmContentSearchEventBus.publish({ query: newQuery, withSummary: true });
  }, []);

  const { conditionSearchTerms, categorizedSearchTermMapping } = useMemo(
    () =>
      selectedCondition
        ? getConditionSearchTerms(selectedCondition)
        : { conditionSearchTerms: [], categorizedSearchTermMapping: {} as Record<ConditionTermCategory, string[]> },
    [selectedCondition, getConditionSearchTerms],
  );

  const selectableTabOptions = useMemo(
    () =>
      keys(categorizedSearchTermMapping)
        .filter((category) => !!categorizedSearchTermMapping[category].length)
        .map((category) => ({
          value: category,
          label: CONDITION_TERM_CATEGORY_LABEL_MAPPING[category],
        })),
    [categorizedSearchTermMapping],
  );

  useEffect(() => {
    if (selectableTabOptions.length && selectableTabOptions.every((option) => option.value !== selectedCategory)) {
      selectTermCategory(selectableTabOptions[0].value);
    }
  }, [selectableTabOptions, selectedCategory]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const query = searchParams.get('query');

    if (query) {
      setSelectedTerms(getTermsFromQuery(query));
    }
  }, []);

  const isOpened = opened || alwaysShow;

  const termsByCategory = useMemo(
    () => categorizedSearchTermMapping[selectedCategory] || [],
    [categorizedSearchTermMapping, selectedCategory],
  );

  const termsWithNoResults = useMemo(
    () => termsByCategory.filter((term) => !scoreResultByTerm[term]?.score),
    [termsByCategory, scoreResultByTerm],
  );

  const renderToggleTermsWithNoResults = () => {
    if (!termsWithNoResults.length) return null;

    if (showTermsWithNoResults) {
      return (
        <UnderlinedTextButton
          sx={{ alignSelf: 'center', fontSize: '0.75rem', marginTop: 4, marginBottom: 6 }}
          onClick={() => setShowSearchTermsWithNoResults(false)}
        >
          Hide terms with no results <IconChevronUp size={12} />
        </UnderlinedTextButton>
      );
    }

    return (
      <UnderlinedTextButton
        sx={{ alignSelf: 'center', fontSize: '0.75rem', marginTop: 4, marginBottom: 6 }}
        onClick={() => setShowSearchTermsWithNoResults(true)}
      >
        Show {termsWithNoResults.length} {singularOrPlural('term', 'terms')(termsWithNoResults.length)} with no results{' '}
        <IconChevronDown size={12} />
      </UnderlinedTextButton>
    );
  };

  const renderContent = () => {
    return (
      <Transition mounted={opened} transition="fade">
        {(style) => (
          <Flex
            style={style}
            gap={4}
            align="center"
            sx={(theme) => ({
              color: isError ? theme.colors.red[5] : theme.colors.dark[4],
            })}
          >
            {(() => {
              if (isError)
                return (
                  <>
                    <IconBan size={12} />

                    <Text size="0.75rem" fw={500} color="red">
                      Could not load search terms for condition
                    </Text>
                  </>
                );

              if (isLoading)
                return (
                  <>
                    <Loader size={12} />

                    <Text size="0.75rem" fw={500} color="dark.3">
                      Loading search terms for condition...
                    </Text>
                  </>
                );

              if (!selectedCondition)
                return (
                  <>
                    <IconInfoCircle size={14} />

                    <Text fz="0.75rem" color="dark">
                      You need to select a condition first before the search terms can be shown here.
                    </Text>
                  </>
                );

              return (
                <Flex style={style} gap={4} sx={{ flexWrap: 'wrap' }}>
                  <ConditionSearchTermChips
                    terms={termsByCategory}
                    value={terms}
                    onSelectMultiple={handleSelect}
                    multiple
                  />

                  {renderToggleTermsWithNoResults()}
                </Flex>
              );
            })()}
          </Flex>
        )}
      </Transition>
    );
  };

  return (
    <Box
      data-testid="search-term-container"
      mb="sm"
      sx={{
        width: '100%',
        '> div': {
          width: '100%',
          '> div': {
            width: '100%',
          },
        },
      }}
    >
      <ScrollArea.Autosize
        mah={mah}
        sx={(theme) => ({
          borderRadius: 4,
          border: `1px solid ${theme.colors.gray[3]}`,
          ...(!isOpened
            ? {
                cursor: 'pointer',
                '&:hover': {
                  background: theme.fn.lighten(theme.colors.gray[0], 0.5),
                },
              }
            : {}),
        })}
      >
        <Flex
          direction="column"
          gap={6}
          pl="xs"
          pr="sm"
          py="xs"
          onClick={!isOpened ? toggleShowMedicalTermSearch : undefined}
        >
          <Flex align="center" justify="space-between" gap="md" sx={{ height: 28 }}>
            <Flex align="center" gap={4}>
              <Tooltip
                label={selectedCondition?.headerCondition}
                withArrow
                withinPortal
                openDelay={500}
                {...(!selectedCondition ? { opened: false } : {})}
              >
                <Text
                  display="flex"
                  fz="0.875rem"
                  color="dark.4"
                  fw={500}
                  sx={{
                    gap: 4,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  Search medical terms associated with{' '}
                  {selectedCondition ? (
                    <Text
                      fw={600}
                      sx={{
                        maxWidth: !isOpened ? 'unset' : selectedCondition.isServiceConnected ? 220 : 288,
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      component="strong"
                    >
                      {selectedCondition.headerCondition}
                    </Text>
                  ) : (
                    'current condition'
                  )}
                </Text>
              </Tooltip>

              {isOpened && selectedCondition && <ConditionSearchTermHint />}
            </Flex>

            <Flex align="center" gap="xs">
              {!isLoading && (
                <Transition mounted={opened} transition="fade">
                  {(style) => (
                    <Tabs
                      style={style}
                      value={selectedCategory}
                      onTabChange={selectTermCategory}
                      sx={{
                        whiteSpace: 'nowrap',
                        '.ghost-Tabs-tab': {
                          padding: '4px 6px',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      }}
                    >
                      <Tabs.List sx={{ flexWrap: 'nowrap' }}>
                        {selectableTabOptions.map((option) => {
                          return (
                            <Tabs.Tab key={option.value} value={option.value}>
                              {option.label}
                            </Tabs.Tab>
                          );
                        })}
                      </Tabs.List>
                    </Tabs>
                  )}
                </Transition>
              )}

              {!alwaysShow && (
                <Tooltip label={isOpened ? 'Hide search terms' : 'Show search terms'} withinPortal withArrow>
                  <ActionIcon
                    size="xs"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleShowMedicalTermSearch();
                    }}
                  >
                    {isOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </Flex>
          </Flex>

          {renderContent()}
        </Flex>
      </ScrollArea.Autosize>
    </Box>
  );
};

export const ConditionSearchTermContainer = memo(ConditionSearchTermContainerBase);
