import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAccessToken = () => {
  const { getAccessTokenSilently: getAccessTokenSilentlyBase } = useAuth0();

  const getAccessTokenSilently = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const temporaryAccessToken = searchParams.get('tat');

    if (temporaryAccessToken) return temporaryAccessToken;

    return getAccessTokenSilentlyBase();
  }, [getAccessTokenSilentlyBase]);

  return { getAccessTokenSilently };
};
