import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@mantine/core';

import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { ExportConditionsLayout } from '@/pageAI/components/medicalConditions/export/ExportConditionsLayout';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';

const ExportConditionSummaryPageBase = () => {
  const { clientId } = useParams();
  const { client, isLoading, isError } = useClient({ clientId });

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading client..."
        description="Please wait while Page retrieves the client."
        fullScreen
      />
    );

  const hasConditionIds = !!window.serverInjection?.conditionSummaryExport?.conditionIds?.length;
  const viewMode = window.serverInjection?.conditionSummaryExport?.viewMode;
  const hideNAFields = window.serverInjection?.conditionSummaryExport?.hideNAFields;
  const cFileIds = window.serverInjection?.conditionSummaryExport?.cFileIds;

  if (!clientId || isError || !hasConditionIds)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder
          {...(!hasConditionIds
            ? {
                title: 'No conditions to export',
                description: 'You need to select some conditions to export.',
              }
            : {})}
        />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  return <ExportConditionsLayout client={client} viewMode={viewMode} hideNAFields={hideNAFields} cFileIds={cFileIds} />;
};

export const ExportConditionSummaryPage = memo(ExportConditionSummaryPageBase);
