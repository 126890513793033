import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Client } from '@/pageAI/@types';
import { showAsyncNotification } from '@/shared/services/notifications';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';
import { FetchStatus, SortOrder } from '@/shared/@types';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import {
  ClientExportJobStatus,
  ClientExportJobType,
  ExportTimelineInput,
  ExportTimelineMutation,
} from '@/pageAI/gql/graphql';
import { exportTimelineMutation } from '@/pageAI/api/export';
import { getExportNotificationContent, pollExportJob } from '@/pageAI/services/export';
import { queryMap } from '../useLastExportJob';
import { downloadFromUrl } from '@/shared/lib/browser';
import { getClientMergedCFileName, getExportedTimelineFileName } from '@/pageAI/services/clients';

interface ExportTimelineProps {
  client: Client;
  eventIds?: string[];
  showBookmarkSigns?: boolean;
  showEventDetails?: boolean;
  showReferencesWithLinks?: boolean;
  tab?: CaseTimelineTab;
  sortOrder?: SortOrder;
  withCFile?: boolean;
}

export const useExportTimeline = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutateAsync: exportTimelineAsync } = useMutation<ExportTimelineMutation, Error, ExportTimelineInput>({
    mutationFn: async (input) => {
      return request(exportTimelineMutation, { input });
    },
  });

  const exportTimeline = useCallback(
    async ({
      client,
      eventIds,
      showBookmarkSigns,
      showEventDetails,
      showReferencesWithLinks,
      tab,
      sortOrder,
      withCFile = false,
    }: ExportTimelineProps) => {
      const { title: loadingTitle, description: loadingDescription } = getExportNotificationContent({
        client,
        exportType: ClientExportJobType.Timeline,
        status: FetchStatus.LOADING,
        withMergedCFile: withCFile,
      });

      const { onSuccess, onError, close } = showAsyncNotification(loadingTitle, loadingDescription, {
        withoutIcon: true,
      });

      try {
        const {
          exportTimeline: { exportJob },
        } = await exportTimelineAsync({
          clientId: client.id,
          includeCFiles: withCFile,
          eventIds,
          showBookmarkSigns,
          showEventDetails,
          showReferencesWithLinks,
          tab,
          sortOrder,
        });

        const finishedExportJob = await pollExportJob(request)(exportJob.id);

        if (finishedExportJob.status === ClientExportJobStatus.Failed) throw new Error('Failed to export timeline');

        if (finishedExportJob.downloadUrl)
          downloadFromUrl(finishedExportJob.downloadUrl, getExportedTimelineFileName(client));

        if (finishedExportJob.cFileDownloadUrl)
          downloadFromUrl(finishedExportJob.cFileDownloadUrl, getClientMergedCFileName(client));

        const { title: successTitle, description: successDescription } = getExportNotificationContent({
          client,
          exportType: ClientExportJobType.Timeline,
          status: FetchStatus.SUCCESS,
          withMergedCFile: withCFile,
          exportDownloadUrl: finishedExportJob.downloadUrl,
          mergedCFileDownloadUrl: finishedExportJob.cFileDownloadUrl,
          onClose: close,
        });

        onSuccess(successTitle, successDescription, {
          withoutIcon: true,
          autoClose: false,
          withCloseButton: false,
        });
      } catch (error) {
        console.error(error);

        const { title: errorTitle, description: errorDescription } = getExportNotificationContent({
          client,
          exportType: ClientExportJobType.Timeline,
          status: FetchStatus.ERROR,
        });

        onError(errorTitle, errorDescription, { withoutIcon: true, autoClose: false });
      }

      queryClient.refetchQueries(queryMap.client.lastExportJob.queryKey(client.id, ClientExportJobType.Timeline));
    },
    [request, exportTimelineAsync, queryClient],
  );

  return { exportTimeline };
};
