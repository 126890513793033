import { useLayoutEffect } from 'react';

import { Client } from '@/pageAI/@types';
import { setPrintPageNumberMapping } from '@/pageAI/components/summaries/SummaryFileReferenceBadge';
import { getClientFiles } from '@/pageAI/services/clients';
import { getFilePageNumberMappingInMergedFile } from '@/pageAI/services/files';

export const useCFileOrder = (client: Client, cFileIds?: string[]) => {
  useLayoutEffect(() => {
    if (!cFileIds?.length) return;

    const { fileAssets } = getClientFiles(client);

    const printPageNumberMapping = getFilePageNumberMappingInMergedFile(fileAssets, cFileIds);

    setPrintPageNumberMapping(printPageNumberMapping);
  }, [cFileIds, client]);
};
