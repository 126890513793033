import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { normalizeSimpleCondition } from '@/pageAI/services/medicalConditions';
import { conditionSummaryDeltasQuery } from '@/pageAI/api';
import { useIncrementalSummaryFeatureFlag } from '../../featureFlags/useIncrementalSummaryFeatureFlag';

const fetchMedicalConditions = async (request: GraphQLRequestFunction, clientId: string | undefined) => {
  if (typeof clientId !== 'string') return Promise.resolve(null);

  const responseBody = await request(conditionSummaryDeltasQuery, { id: clientId });

  const normalizeConditionSummaryDeltas = (
    rawConditionSummaryDeltas: typeof responseBody.client.conditionSummaryDeltas,
  ) => {
    return rawConditionSummaryDeltas.map((conditionSummaryDelta) => ({
      ...conditionSummaryDelta,
      newCondition: normalizeSimpleCondition(conditionSummaryDelta.newCondition),
      prevConditionHints: conditionSummaryDelta.prevConditionHints.map((prevConditionHint) => ({
        ...prevConditionHint,
        prevCondition: normalizeSimpleCondition(prevConditionHint.prevCondition),
      })),
    }));
  };

  const conditionSummaryDeltas = normalizeConditionSummaryDeltas(responseBody.client.conditionSummaryDeltas);

  return conditionSummaryDeltas;
};

export const queryMap = {
  client: {
    conditionSummaryDeltas: {
      queryKey: (clientId?: string) => ['client', clientId, 'conditionSummaryDeltas'],
      queryFn: fetchMedicalConditions,
    },
  },
};

export type ConditionSummaryDeltasQuery = Awaited<ReturnType<typeof queryMap.client.conditionSummaryDeltas.queryFn>>;

interface UseConditionSummaryDeltasProps {
  clientId: string;
}

export const useConditionSummaryDeltas = ({ clientId }: UseConditionSummaryDeltasProps) => {
  const { request } = useGraphQLRequest();
  const { enabled } = useIncrementalSummaryFeatureFlag();

  const { data, isLoading, isError } = useQuery(queryMap.client.conditionSummaryDeltas.queryKey(clientId), () =>
    enabled ? queryMap.client.conditionSummaryDeltas.queryFn(request, clientId) : Promise.resolve(null),
  );

  return {
    conditionSummaryDeltas: data || [],
    isLoading,
    isError,
  };
};
