import { ConditionSummaryDelta } from '@/pageAI/@types';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import { IncrementalSummaryDeltaStatus } from '@/pageAI/gql/graphql';

type ConditionSummaryDeltaMapping = Record<string, ConditionSummaryDelta | undefined>;

export const shouldReviewIncrementalChanges = (deltas: ConditionSummaryDelta[] | undefined | null) => {
  return deltas?.some((delta) => delta.status === IncrementalSummaryDeltaStatus.ReviewNeeded);
};

export const getDeltaByConditionMapping = (deltas: ConditionSummaryDelta[] | undefined | null) => {
  return deltas?.reduce((acc, delta) => {
    acc[delta.newCondition.id] = delta;

    return acc;
  }, {} as Record<string, ConditionSummaryDelta | undefined>);
};

export const doesUpdatedConditionNeedReview = (
  updatedCondition: SimpleCondition,
  deltaByConditionMapping: Record<string, ConditionSummaryDelta | undefined>,
) => {
  const equivalentDelta = deltaByConditionMapping[updatedCondition.id];

  if (!equivalentDelta) return false;

  return [IncrementalSummaryDeltaStatus.ReviewNeeded, IncrementalSummaryDeltaStatus.Reviewed].includes(
    equivalentDelta.status,
  );
};

export const getPreviousConditionsNeedingReview = (deltas: ConditionSummaryDelta[] | undefined | null) => {
  const previousConditions =
    deltas?.map((delta) => delta.prevConditionHints.map((hint) => hint.prevCondition)).flat() || [];

  const uniqueConditions = new Map(previousConditions.map((condition) => [condition.id, condition]));

  return Array.from(uniqueConditions.values());
};

export const isNewCondition = (condition: SimpleCondition, equivalentDelta: ConditionSummaryDelta) => {
  return (
    equivalentDelta.newCondition.id === condition.id &&
    equivalentDelta.status === IncrementalSummaryDeltaStatus.ReviewNeeded &&
    equivalentDelta.prevConditionHints.length === 0
  );
};

export const getNewConditions = (conditions: SimpleCondition[], deltaMapping: ConditionSummaryDeltaMapping) => {
  return conditions.filter((condition) => {
    const delta = deltaMapping[condition.id];

    return delta && isNewCondition(condition, delta);
  });
};
