import { ActionIcon, Anchor, Flex, Loader, Text, ThemeIcon } from '@mantine/core';
import { IconDownload, IconPdf, IconX } from '@tabler/icons-react';

import { exportJobQuery } from '@/pageAI/api/export';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { queryClient } from '@/shared/lib/react-query';
import { wait } from '@/shared/utils/time';
import { Client, ExportJob } from '@/pageAI/@types';
import {
  getClientMergedCFileName,
  getExportedConditionSummaryFileName,
  getClientDisplayName,
  getExportedTimelineFileName,
} from '@/pageAI/services/clients';
import { ClientExportJobStatus, ClientExportJobType } from '@/pageAI/gql/graphql';
import { FetchStatus } from '@/shared/@types';
import { downloadFromUrl } from '@/shared/lib/browser';

const fetchExportJob =
  (request: GraphQLRequestFunction) =>
  async (id: string, counter = 0) => {
    const response = await queryClient.fetchQuery({
      queryKey: ['export-job', id, counter],
      queryFn: async () => {
        return request(exportJobQuery, { id });
      },
    });

    return response.clientExportJob;
  };

export const pollExportJob =
  (request: GraphQLRequestFunction) =>
  (id: string, interval = 10000) => {
    let counter = 0;

    const poll = async (): Promise<ExportJob> => {
      const exportJob = await fetchExportJob(request)(id, counter);

      if ([ClientExportJobStatus.Completed, ClientExportJobStatus.Failed].includes(exportJob.status)) return exportJob;

      await wait(interval);

      counter++;

      return poll();
    };

    return poll();
  };

interface GetNotificationContentProps {
  client: Client;
  status: FetchStatus;
  withMergedCFile?: boolean;
  exportDownloadUrl?: string | null;
  mergedCFileDownloadUrl?: string | null;
  exportType?: ClientExportJobType;
  onClose?: () => void;
}

export const getExportNotificationContent = ({
  client,
  status,
  withMergedCFile = false,
  exportDownloadUrl,
  mergedCFileDownloadUrl,
  exportType = ClientExportJobType.ConditionSummary,
  onClose,
}: GetNotificationContentProps) => {
  const clientFullName = getClientDisplayName(client);
  const exportFileName =
    exportType === ClientExportJobType.ConditionSummary
      ? getExportedConditionSummaryFileName(client)
      : getExportedTimelineFileName(client);
  const mergedCFileName = getClientMergedCFileName(client);

  let title: React.ReactNode = <></>;
  let description: React.ReactNode = <></>;

  const handleDownloadExportedFile = () => {
    if (!exportDownloadUrl) return;

    downloadFromUrl(exportDownloadUrl, exportFileName);
  };

  const handleDownloadMergedCFile = () => {
    if (!mergedCFileDownloadUrl) return;

    downloadFromUrl(mergedCFileDownloadUrl, mergedCFileName);
  };

  const renderFileRow = (fileName: string, onClick: () => void, isLoading: boolean) => {
    return (
      <Flex align="center" justify="space-between" gap="xs">
        <Flex align="center" gap={6} mt={4} sx={{ opacity: isLoading ? '0.5' : '1', width: 'calc(100% - 32px)' }}>
          <ThemeIcon color="red.6" size="xs">
            <IconPdf size={16} />
          </ThemeIcon>

          <Anchor
            color="dark"
            onClick={(event) => {
              event.preventDefault();

              if (!isLoading) onClick();
            }}
            sx={{
              pointerEvents: isLoading ? 'none' : 'auto',
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {fileName}
          </Anchor>
        </Flex>

        {isLoading ? (
          <Loader size={16} />
        ) : (
          <ActionIcon size="xs" onClick={onClick}>
            <IconDownload size={16} />
          </ActionIcon>
        )}
      </Flex>
    );
  };

  switch (status) {
    case FetchStatus.LOADING: {
      title = (
        <>
          Exporting files for <strong>{clientFullName}</strong>...
        </>
      );

      description = (
        <Flex direction="column">
          <Text fz="0.75rem" color="dark.3">
            If you close Page, your download will remain accessible via the Export button.
          </Text>

          {renderFileRow(exportFileName, handleDownloadExportedFile, true)}
          {withMergedCFile ? renderFileRow(mergedCFileName, handleDownloadMergedCFile, true) : null}
        </Flex>
      );

      break;
    }

    case FetchStatus.SUCCESS: {
      title = (
        <Flex justify="space-between" gap={4}>
          <Text fz="0.875rem">
            Successfully exported files for <strong>{clientFullName}</strong>
          </Text>

          <ActionIcon size="xs" onClick={onClose}>
            <IconX size={14} />
          </ActionIcon>
        </Flex>
      );

      description = (
        <Flex direction="column">
          {renderFileRow(exportFileName, handleDownloadExportedFile, false)}
          {withMergedCFile ? renderFileRow(mergedCFileName, handleDownloadMergedCFile, false) : null}
        </Flex>
      );

      break;
    }

    case FetchStatus.ERROR: {
      title = (
        <>
          Failed to export {exportType === ClientExportJobType.ConditionSummary ? 'condition summary' : 'timeline'} for{' '}
          <strong>{clientFullName}</strong>
        </>
      );

      description = <>Please try again later or contact support if the issue persists.</>;

      break;
    }

    default:
  }

  return { title, description };
};
