export const moveCaretToEnd = (element?: HTMLElement) => {
  const selection = window.getSelection();

  if (!selection) return;

  if (element) selection.selectAllChildren(element);

  selection.collapseToEnd();
};

export const getCurrentCaretPosition = (
  containerElement?: HTMLElement,
  excludedElements?: (HTMLElement | undefined | null)[],
) => {
  const selection = window.getSelection();

  const range = selection?.getRangeAt(0);

  if (!range) return null;

  if (containerElement)
    if (
      (range.startContainer !== containerElement && !containerElement.contains(range.startContainer)) ||
      containerElement === range.startContainer
    ) {
      return null;
    }

  if (excludedElements) {
    for (const excludedElement of excludedElements) {
      if (excludedElement === range.startContainer) return null;
    }
  }

  const rect = range.getClientRects()[0];

  if (rect) return rect;

  const divElement = document.createElement('div');
  range.insertNode(divElement);

  const divElementRect = divElement.getBoundingClientRect();

  divElement.remove();

  return divElementRect;
};

export const isInViewport = (element: HTMLElement) => {
  if (!element) return false;
  if (1 !== element.nodeType) return false;

  const html = document.documentElement;
  const rect = element.getBoundingClientRect();

  return (
    !!rect && rect.bottom >= 0 && rect.right >= 0 && rect.left <= html.clientWidth && rect.top <= html.clientHeight
  );
};

interface GetImageDataFromCanvasOptions {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export const getImageDataFromCanvas = (canvas: HTMLCanvasElement, options: GetImageDataFromCanvasOptions) => {
  const context = canvas.getContext('2d');
  const x = options.x || 0;
  const y = options.y || 0;
  const width = options.width || canvas.width;
  const height = options.height || canvas.height;

  const imageData = context?.getImageData(x, y, width, height);

  return imageData;
};

export const getImageURLFromCanvas = (canvas: HTMLCanvasElement, options: GetImageDataFromCanvasOptions) => {
  const width = options.width || canvas.width;
  const height = options.height || canvas.height;

  const imageData = getImageDataFromCanvas(canvas, { ...options, width, height });

  if (!imageData) return;

  const offscreenCanvas = document.createElement('canvas');
  offscreenCanvas.width = width;
  offscreenCanvas.height = height;
  const tempContext = offscreenCanvas.getContext('2d');

  // Put ImageData onto the offscreen canvas
  tempContext?.putImageData(imageData, 0, 0);

  // Convert canvas content to a data URL
  const imageURL = offscreenCanvas.toDataURL('image/webp', 0.5);

  return imageURL;
};

export const isSelectingText = (event: MouseEvent | React.MouseEvent) => {
  if (!event.target) return false;

  const computedCursor = getComputedStyle(event.target as HTMLElement).cursor;

  if (computedCursor === 'text') return true;

  const isThereSelectedText = !!window.getSelection()?.toString().length;

  return isThereSelectedText;
};
