import React from 'react';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconExclamationMark } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

interface ShowAsyncNotificationOpts {
  withoutIcon?: boolean;
  withCloseButton?: boolean;
  autoClose?: boolean | number;
}

export const showAsyncNotification = (
  title: React.ReactNode,
  message: React.ReactNode,
  opts?: ShowAsyncNotificationOpts,
) => {
  const notificationId = uuidv4();

  notifications.show({
    id: notificationId,
    ...(opts?.withoutIcon ? {} : { loading: true }),
    title,
    message,
    autoClose: opts?.autoClose ?? false,
    withCloseButton: opts?.withCloseButton ?? false,
    withBorder: true,
  });

  const onSuccess = (title: React.ReactNode, message: React.ReactNode, opts?: ShowAsyncNotificationOpts) => {
    notifications.update({
      id: notificationId,
      title,
      message,
      loading: false,
      ...(opts?.withoutIcon ? {} : { icon: <IconCheck size="1rem" /> }),
      color: 'teal',
      autoClose: opts?.autoClose ?? 5000,
      withCloseButton: opts?.withCloseButton ?? true,
      withBorder: true,
    });
  };

  const onError = (title: React.ReactNode, message: React.ReactNode, opts?: ShowAsyncNotificationOpts) => {
    notifications.update({
      id: notificationId,
      title,
      message,
      loading: false,
      ...(opts?.withoutIcon ? {} : { icon: <IconExclamationMark size="1rem" /> }),
      color: 'red',
      autoClose: opts?.autoClose ?? 5000,
      withCloseButton: opts?.withCloseButton ?? true,
      withBorder: true,
    });
  };

  return { onSuccess, onError, close: () => notifications.hide(notificationId) };
};
