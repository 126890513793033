import { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload } from '@tabler/icons-react';

import { ExportConditionsModal } from '../ExportConditionsModal';
import { conditionsSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { useExportConditionSummary } from '@/pageAI/hooks/export/useExportConditionSummary';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import {
  categorizeConditions,
  sortConditionsAlphebetically,
  sortConditionsByRating,
} from '@/pageAI/services/medicalConditions';

interface ExportConditionsButtonProps {}

const ExportConditionsButtonBase = (props: ExportConditionsButtonProps) => {
  const client = useSelectedClient(true);
  const { exportConditionSummary } = useExportConditionSummary();

  const [opened, { open, close }] = useDisclosure();
  const conditions = useUnifiedStore(conditionsSelector);
  const [selectedConditionIds, setSelectedConditionIds] = useState<string[]>(() =>
    conditions.map((condition) => condition.id),
  );

  const handleSelectConditions = useCallback((conditionIds: string[]) => {
    setSelectedConditionIds([...new Set(conditionIds)]);
  }, []);

  const handleConfirmExport = useCallback(
    async (withCFile?: boolean) => {
      close();

      const selectedSimpleConditions = conditions.filter((condition) => selectedConditionIds.includes(condition.id));

      const { claimedConditions, potentialConditions } = categorizeConditions(selectedSimpleConditions);

      const conditionsToPrint = [
        ...sortConditionsByRating(claimedConditions),
        ...sortConditionsAlphebetically(potentialConditions),
      ];

      const { viewMode, showNullFields } = useUnifiedStore.getState().summaryTabState;

      await exportConditionSummary({
        client,
        withCFile,
        conditionIds: conditionsToPrint.map((condition) => condition.id),
        viewMode,
        hideNAFields: !showNullFields,
      });

      return;
    },
    [close, client, exportConditionSummary, selectedConditionIds, conditions],
  );

  return (
    <>
      {createPortal(
        <Tooltip label="Export condition summary" withinPortal withArrow>
          <ActionIcon onClick={open} color="gray.6" sx={{ order: 2 }} data-testid="export-condition-summary">
            <IconDownload size={16} />
          </ActionIcon>
        </Tooltip>,
        document.querySelector('#page-ai-header-suffix-section') || document.body,
      )}

      <ExportConditionsModal
        opened={opened}
        onClose={close}
        selectedConditionIds={selectedConditionIds}
        onSelect={handleSelectConditions}
        onExport={handleConfirmExport}
      />
    </>
  );
};

export const ExportConditionsButton = memo(ExportConditionsButtonBase);
