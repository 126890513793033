import { EventBus } from 'native-pubsub';

import { FileAsset, FileSearchResult, FileSearchResultGroup, FileUploadStatus } from '@/shared/@types';
import { FileTab } from './files.services.types';
import { FileGroup, FileOcrStatus, FileSplitStatus } from '@/pageAI/gql/graphql';
import { truthy } from '@/shared/utils/boolean';
import { UnifiedTab } from '../medicalConditions';
import { FileDisplayStatus, getFileDisplayStatus } from '@/shared/services/files';

export const reviewUnrecognizedFilesPubsub = new EventBus();
export const reviewDuplicatedFilesPubsub = new EventBus();
export const sortFailedFilesPubsub = new EventBus();

export const getFileAssetClassificationText = (fileAsset: FileAsset) => {
  return fileAsset.metadata?.pageAIMetadata?.eventTypeClassifications?.[0] || 'Unclassified';
};

export const isFileAssetUnreadable = (fileAsset: FileAsset) => {
  return getFileDisplayStatus(fileAsset) === FileDisplayStatus.UNREADABLE;
};

export const filterUnreadableFileAssets = (fileAssets: FileAsset[]) => {
  return fileAssets.filter(isFileAssetUnreadable);
};

export const isFileAssetUnrecognized = (fileAsset: FileAsset) => {
  return !isFileSpitting(fileAsset) && !fileAsset.metadata?.pageAIMetadata?.isVAFileTypeRecognized;
};

export const filterUnrecognizedFileAssets = (fileAssets: FileAsset[]) => {
  return fileAssets.filter(isFileAssetUnrecognized);
};

export const isFileAssetDuplicated = (fileAsset: FileAsset) => {
  return fileAsset.status.ocrStatus === FileOcrStatus.Duplicated;
};

export const filterDuplicatedFileAssets = (fileAssets: FileAsset[]) => {
  return fileAssets.filter(isFileAssetDuplicated);
};

export const getFilesToReviewDuplication = (fileAssets: FileAsset[]) => {
  const duplicatedFiles = filterDuplicatedFileAssets(fileAssets);

  const filesToReview: FileAsset[] = [];

  duplicatedFiles.forEach((fileAsset) => {
    if (!fileAsset.duplicateOf) return;

    filesToReview.push(fileAsset, fileAsset.duplicateOf);
  });

  return filesToReview.map(getFileAssetWithExtractedInfo);
};

export const filterImportantFileAssets = (fileAssets: FileAsset[]) => {
  return fileAssets.filter((fileAsset) => fileAsset.isImportant);
};

export const getFileFilterValuesFromSearchParams = (searchParams: URLSearchParams) => {
  const tab = searchParams.get('fileTab') as FileTab;

  return {
    tab,
  };
};

export const isFileFromEFolder = (fileAsset: FileAsset) => {
  return fileAsset.metadata?.pageAIMetadata?.indexNumber.startsWith('CF');
};

export const isFileSpitting = (fileAsset: FileAsset) => {
  return fileAsset.status.splitStatus === FileSplitStatus.Splitting;
};

export const isThereSplittingFile = (fileAssets: FileAsset[]) => {
  return fileAssets.some(isFileSpitting);
};

export const isCombinedCFile = (fileAsset: FileAsset) =>
  (isFileSpitting(fileAsset) || fileAsset.isCombinedCfile) && fileAsset.status.splitStatus !== FileSplitStatus.Failed;

export const filterCombinedCFiles = (fileAssets: FileAsset[]) => {
  return fileAssets.filter((fileAsset) => !isCombinedCFile(fileAsset));
};

export const filterFileAssetsByKeywords = (fileAssets: FileAsset[], keywords: string | null) => {
  if (!keywords) return fileAssets;

  return fileAssets.filter((fileAsset) => {
    const metadata = fileAsset.metadata?.pageAIMetadata;

    if (!metadata) return false;

    const loweredCaseKeywords = keywords.toLowerCase();

    return (
      metadata.vaFileTypeName.toLowerCase().includes(loweredCaseKeywords) ||
      metadata.indexNumber.toLowerCase().includes(loweredCaseKeywords)
    );
  });
};

export const getFileNamesFromFileGroups = (fileGroups: FileGroup[]) => {
  return fileGroups.flatMap((fileGroup) => fileGroup.fileNames);
};

export const getClientFileSearchPath = (clientId: string, query?: string | null) =>
  [
    `/clients/${clientId}/summaries?tab=${encodeURIComponent(UnifiedTab.CONTENT_SEARCH)}`,
    query ? `&query=${encodeURIComponent(query)}` : undefined,
  ]
    .filter(truthy)
    .join('');

export const normalizeFileSearchResult = (result: FileSearchResult): FileSearchResult => {
  return {
    ...result,
    file: {
      ...result.file,
      name: result.file.metadata?.pageAIMetadata
        ? [
            `[${result.file.metadata.pageAIMetadata.indexNumber}]`,
            result.file.metadata.pageAIMetadata.vaFileTypeName,
          ].join(' ')
        : result.file.name,
    },
  };
};

export const groupFileSearchResults = (results: FileSearchResult[]): FileSearchResultGroup[] => {
  return results.map((result) => {
    const normalizedResult = normalizeFileSearchResult(result);

    return {
      file: normalizedResult.file,
      results: [normalizedResult],
      pageNumber: normalizedResult.pageNumber,
    };
  });
};

export const groupFileSearchResultByPage = (results: FileSearchResult[]): Record<number, FileSearchResult[]> => {
  const groupedResults: Record<number, FileSearchResult[]> = {};

  results.forEach((result) => {
    if (!groupedResults[result.pageNumber]) groupedResults[result.pageNumber] = [];

    groupedResults[result.pageNumber].push(result);
  });

  return groupedResults;
};

export const getFileSearchResultElementId = (index: number) => `file-search-result-${index}`;

export const filterFileByFileTypes = (fileAssets: FileAsset[], fileTypes: string[] | null) => {
  if (fileTypes === null) return [];

  if (!fileTypes.length) return fileAssets;

  return fileAssets.filter((fileAsset) => {
    if (!fileAsset.metadata?.pageAIMetadata?.vaFileTypeName) return false;

    return fileTypes.includes(fileAsset.metadata.pageAIMetadata.vaFileTypeName);
  });
};

export const filterFileSearchResults = (results: FileSearchResult[], fileTypes: string[] | null) => {
  if (fileTypes === null) return [];

  if (!fileTypes.length) return results;

  return results.filter((result) => {
    if (!result.file.metadata?.pageAIMetadata?.vaFileTypeName) return false;

    return fileTypes.includes(result.file.metadata.pageAIMetadata.vaFileTypeName);
  });
};

export const getFileSearchFilterValuesFromSearchParams = (searchParams: URLSearchParams) => {
  const fileGroups = searchParams.getAll('fileGroup');
  const fileTypes = searchParams.getAll('fileType');
  const keywords = searchParams.get('fileKeywords');

  return {
    fileGroups,
    fileTypes,
    keywords,
  };
};

export const getFileDisplayName = (fileAsset: FileAsset) => {
  return fileAsset.metadata?.pageAIMetadata
    ? [fileAsset.metadata.pageAIMetadata.vaFileTypeName, `[${fileAsset.metadata.pageAIMetadata.indexNumber}]`].join(' ')
    : fileAsset.name;
};

export const getFileSearchTableOfContents = (results: FileSearchResult[]) => {
  const tableOfContents: Record<string, Record<string, FileSearchResult[]>> = {};

  results.forEach((result) => {
    if (!result.file.fileReceiveDate) return;

    const [year, month] = result.file.fileReceiveDate.split('-');

    if (!tableOfContents[year]) tableOfContents[year] = {};

    if (!tableOfContents[year][month]) tableOfContents[year][month] = [];

    tableOfContents[year][month].push(result);
  });

  return tableOfContents;
};

export const getFilePageNumberMappingInMergedFile = (fileAssets: FileAsset[], mergedFileOrder: string[]) => {
  const pageNumberMapping: Record<string, number> = {};

  let currentPageNumber = 1;

  mergedFileOrder.forEach((fileId, index) => {
    const fileAsset = fileAssets.find((fileAsset) => fileAsset.id === fileId);

    if (!fileAsset) return;

    if (!fileAsset.metadata?.totalPages) return;

    pageNumberMapping[fileId] = currentPageNumber;

    currentPageNumber += fileAsset.metadata.totalPages;
  });

  return pageNumberMapping;
};

export const generateFileReferenceMarkdown = (fileAsset: FileAsset, pageNumbers?: number[]) => {
  // [Rating Decision - Narrative [CF-00400], page 1](/file-preview/gqF0o0ZJTKF2ujAxSkJWMERIR1Q1QUNQTjhNNldOSzhXV1NC/page/1)
  const fileName = getFileDisplayName(fileAsset);
  const fileDisplayText = `${fileName}`;

  if (!Array.isArray(pageNumbers)) {
    return `[${fileDisplayText}](/file-preview/${fileAsset.id})`;
  }

  if (pageNumbers.length === 1) {
    return `[${fileDisplayText}, page ${pageNumbers[0]}](/file-preview/${fileAsset.id}/page/${pageNumbers[0]})`;
  }

  return `[${fileDisplayText}, pages ${pageNumbers[0]}-${pageNumbers.at(-1)}](/file-preview/${fileAsset.id}/page/${
    pageNumbers[0]
  })`;
};

export const getFileAssetWithExtractedInfo = (fileAsset: FileAsset) => {
  const metadata = fileAsset.metadata?.pageAIMetadata;

  return {
    ...fileAsset,
    index:
      fileAsset.status.uploadStatus &&
      [FileUploadStatus.UPLOADING, FileUploadStatus.FAILED].includes(fileAsset.status.uploadStatus)
        ? '--------'
        : metadata?.indexNumber || '--------',
    name: metadata?.vaFileTypeName || fileAsset.name,
  };
};
