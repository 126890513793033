import { memo, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Anchor, Box, Button, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';

import { NoteEntityIndicator } from '../NoteEntityIndicator';
import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { UserAvatar } from '../../users/UserAvatar';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { Note_FullFieldsFragment } from '@/pageAI/gql/graphql';
import { normalizeFile } from '@/shared/services/files';
import { useManageNotes } from '@/pageAI/hooks/notes/useManageNotes';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getConditionName, UnifiedTab } from '@/pageAI/services/medicalConditions';
import { selectCondition } from '@/pageAI/states/unified';
import { getNoteFilePageIndex, getNoteMetadataInfo, getTimelineEventNoteDisplayText } from '@/pageAI/services/notes';
import { getUserDisplayName } from '@/pageAI/services/users';
import { TimeAgo } from '@/shared/components/time/TimeAgo';
import { NoteFileSnippet } from '../NoteFileSnippet';
import { timelineParamsInitialState } from '@/pageAI/hooks/caseTimeline/useTimelineSearchParams';
import { truthy } from '@/shared/utils/boolean';
import { resetConditionSharingState } from '../../medicalConditions/ConditionSummaryContainer/ConditionSummaryContainer.utils';
import { useNotesStore } from '@/pageAI/states/notes';
import { formatDateTime } from '@/shared/utils/date';

interface NoteItemProps {
  note: Note_FullFieldsFragment;
}

const NoteItemBase = ({ note }: NoteItemProps) => {
  const { currentUser } = useCurrentUser();
  const client = useSelectedClient();
  const { updateNote, isUpdating, deleteNote, isDeleting } = useManageNotes(client?.id);

  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(note.content);

  const isPrinting = useNotesStore((state) => state.isPrinting);

  const { snippet, imageUrl, isMarkdown, conditionEntryKey } = useMemo(() => getNoteMetadataInfo(note), [note]);

  const handleDelete = () => {
    modals.openConfirmModal({
      title: 'Delete note',
      children: (
        <Text fz="0.875rem" color="dark">
          Are you sure you want to delete this note?
        </Text>
      ),
      zIndex: 302,
      labels: {
        confirm: 'Delete',
        cancel: 'Cancel',
      },
      cancelProps: {
        variant: 'subtle',
        color: 'gray.7',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () => {
        deleteNote({ noteId: note.id });
      },
    });
  };

  const handleUpdate = () => {
    updateNote({
      noteId: note.id,
      content,
    });

    setIsEditing(false);
  };

  const renderConditionOrEvent = () => {
    if (note.timelineEvent) {
      const eventDisplayText = getTimelineEventNoteDisplayText(note.timelineEvent);

      return (
        <Tooltip openDelay={500} label={eventDisplayText}>
          <Anchor
            color="blue.6"
            component={Link}
            onClickCapture={() => {
              timelineParamsInitialState.isHardRefresh = true;
            }}
            to={`/clients/${client?.id}/summaries?tab=${UnifiedTab.TIMELINE}&eventId=${note.timelineEvent.id}`}
          >
            <Text
              fz="0.75rem"
              fw={500}
              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240, overflow: 'hidden' }}
            >
              {eventDisplayText}
            </Text>
          </Anchor>
        </Tooltip>
      );
    }

    if (note.medicalCondition) {
      const conditionName = note.medicalCondition ? getConditionName(note.medicalCondition) : '';

      return (
        <Tooltip openDelay={500} label={conditionName} withinPortal withArrow>
          <Anchor
            color="blue.6"
            component={Link}
            onClickCapture={() => resetConditionSharingState.reset()}
            onClick={() => requestIdleCallback(() => selectCondition(note.medicalCondition?.id))}
            to={[
              `/clients/${client?.id}/summaries?tab=${UnifiedTab.CONDITION_SUMMARY}&medicalConditionId=${note.medicalCondition.id}`,
              conditionEntryKey ? `&entryKey=${conditionEntryKey}` : '',
            ]
              .filter(truthy)
              .join('')}
          >
            <Text
              fz="0.75rem"
              fw={500}
              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240, overflow: 'hidden' }}
            >
              {conditionName}
            </Text>
          </Anchor>
        </Tooltip>
      );
    }

    return null;
  };

  const renderHeader = () => {
    const author = note.author || currentUser;

    return (
      <Flex align="center" justify="space-between" mt={6} gap={24} mb={4}>
        <Flex align="center" gap={4}>
          {author && (
            <>
              <UserAvatar size={20} user={author} />

              <Text fz="0.75rem" color="dark.4" fw={500} mr={2}>
                {getUserDisplayName(author)}
              </Text>

              {isPrinting ? (
                <Text
                  fz="0.625rem"
                  color="dark.3"
                  sx={{
                    display: 'inline-block',
                    transition: 'all 0.1s',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatDateTime(note.createdAt)}
                </Text>
              ) : (
                <TimeAgo date={note.createdAt} />
              )}
            </>
          )}
        </Flex>

        <Flex align="center" gap={4}>
          {renderConditionOrEvent()}

          {client?.viewerCanUpdate ? (
            <Flex align="center" gap={4}>
              <Menu withinPortal shadow="sm">
                <Menu.Target>
                  <ActionIcon size="xs" className="unprintable">
                    <IconDotsVertical size={14} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item icon={<IconPencil size={14} />} onClick={() => setIsEditing(true)}>
                    Edit
                  </Menu.Item>

                  <Menu.Item color="red" icon={<IconTrash size={14} />} onClick={handleDelete}>
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      direction="column"
      className="normalized-markdown"
      sx={(theme) => ({
        background: theme.white,
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 4,
        padding: '0px 8px 8px',
        transition: 'all 0.2s ease-in-out',
        '*': {
          wordBreak: 'break-word',
        },
        ...(isPrinting
          ? {
              borderColor: theme.colors.gray[6],
              minHeight: 'fit-content',
              breakInside: 'avoid',
              pageBreakInside: 'avoid',
            }
          : {
              overflow: 'auto',
              '&:hover': {
                borderColor: theme.colors.gray[6],
              },
            }),
      })}
    >
      {renderHeader()}

      {content || isEditing ? (
        <Box pos="relative">
          <InlineEditor
            data={content}
            onDataChange={setContent}
            readonly={!isEditing || isPrinting}
            autofocus={isEditing}
          />
        </Box>
      ) : null}

      <NoteFileSnippet
        snippet={snippet}
        imageUrl={imageUrl}
        isMarkdown={isMarkdown}
        alwaysExpanded
        sx={{ marginTop: 2, marginBottom: 4 }}
      />

      <Flex align="center" justify="space-between" gap={24} mt={4}>
        <Flex align="center" gap={6}>
          {note.file && (
            <NoteEntityIndicator
              note={note}
              fileAsset={normalizeFile(note.file)}
              pageNumbers={[(getNoteFilePageIndex(note) || 0) + 1]}
            />
          )}
        </Flex>

        {isEditing && (
          <Flex align="center" justify="end" gap={4} mt={4} className="unprintable">
            <Button size="xs" variant="subtle" color="gray.7" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>

            <Button size="xs" onClick={handleUpdate} variant="light" color="gray.7">
              Save
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const NoteItem = memo(NoteItemBase);
