import { isSelectingText } from '@/shared/lib/dom';
import { useCallback, useEffect, useState } from 'react';

interface UseMouseDraggingProps {
  x?: number;
  y?: number;
  setX?: (x: number) => void;
  setY?: (y: number) => void;
  onDragging?: (deltaX: number, deltaY: number, clientX: number, clientY: number) => void;
  disabled?: boolean;
  stopPropagation?: boolean;
}

export const useMouseDragging = ({
  x,
  y,
  setX,
  setY,
  onDragging,
  disabled = false,
  stopPropagation = false,
}: UseMouseDraggingProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialMouseY, setInitialMouseY] = useState(0);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);

  useEffect(() => {
    if (!isDragging || disabled) return;

    const handleMouseMove = (event: MouseEvent) => {
      if (stopPropagation) event.stopPropagation();

      const deltaX = event.clientX - initialMouseX;
      const deltaY = event.clientY - initialMouseY;
      setX?.(initialX + deltaX);
      setY?.(initialY + deltaY);
      onDragging?.(deltaX, deltaY, event.clientX, event.clientY);

      if (onDragging) {
        setInitialMouseX(event.clientX);
        setInitialMouseY(event.clientY);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, initialMouseX, initialMouseY, initialX, initialY, setX, setY, onDragging, stopPropagation, disabled]);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (disabled || isSelectingText(event)) return;

      if (stopPropagation) event.stopPropagation();

      setIsDragging(true);
      setInitialMouseX(event.clientX);
      setInitialMouseY(event.clientY);
      setInitialX(x || 0);
      setInitialY(y || 0);
    },
    [x, y, disabled, stopPropagation],
  );

  return { isDragging, onMouseDown };
};
