import { memo } from 'react';
import { Box, Text, Tooltip } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';

import { ClaimStatus } from '@/pageAI/gql/graphql';
import { formatUSDate, getDaysDiff, getOneYearFromDate, parseDateOnly } from '@/shared/utils/date';
import {
  CONDITION_CLAIM_STATUS_COLOR_MAPPING,
  CONDITION_CLAIM_STATUS_TEXT_MAPPING,
} from '@/pageAI/services/medicalConditions';

interface ClaimStatusInfoProps {
  claimStatus?: ClaimStatus | null;
  sourceDocumentDate?: string | null;
  dateToDisplay?: string | null;
  dateHint?: string;
}

const ClaimStatusInfoBase = ({ claimStatus, sourceDocumentDate, dateToDisplay, dateHint }: ClaimStatusInfoProps) => {
  if (!claimStatus) return null;

  const colorCode = CONDITION_CLAIM_STATUS_COLOR_MAPPING[claimStatus];

  const renderDateText = () => {
    if (!sourceDocumentDate) return null;

    if (claimStatus === ClaimStatus.Open) {
      const dueDate = getOneYearFromDate(parseDateOnly(dateToDisplay || sourceDocumentDate));
      const daysDiff = getDaysDiff(new Date(), dueDate);
      const formattedDueDate = formatUSDate(dueDate);
      const dateNode = dateHint ? (
        <Tooltip label={dateHint} withArrow withinPortal>
          <Text component="span" sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>
            {formattedDueDate}
          </Text>
        </Tooltip>
      ) : (
        <Text component="span">{formattedDueDate}</Text>
      );

      if (daysDiff < 0) {
        return (
          <>
            until {dateNode} ({Math.ceil(Math.abs(daysDiff))} days remaining)
          </>
        );
      }

      return (
        <>
          until {dateNode} ({Math.ceil(Math.abs(daysDiff))} days overdue)
        </>
      );
    }

    const daysSinceLastFiling = Math.ceil(Math.abs(getDaysDiff(parseDateOnly(sourceDocumentDate), new Date())));

    if (claimStatus === ClaimStatus.PendingAdjudication) {
      return `as of ${formatUSDate(
        dateToDisplay || sourceDocumentDate,
      )} (${daysSinceLastFiling} days since last filing)`;
    }

    if (claimStatus === ClaimStatus.Closed) {
      const formattedDate = formatUSDate(dateToDisplay || sourceDocumentDate);
      const dateNode = dateHint ? (
        <Tooltip label={dateHint} withArrow withinPortal>
          <Text component="span" sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>
            {formattedDate}
          </Text>
        </Tooltip>
      ) : (
        <Text component="span">{formattedDate}</Text>
      );

      return (
        <>
          as of {dateNode} ({daysSinceLastFiling} days since last decision)
        </>
      );
    }

    return null;
  };

  return (
    <Box display="inline">
      <Box
        display="inline-block"
        sx={(theme) => ({
          color: theme.colors[colorCode][5],
        })}
        mr={4}
      >
        <IconCircleFilled size={8} />
      </Box>
      <Text fw={600} component="span">
        {CONDITION_CLAIM_STATUS_TEXT_MAPPING[claimStatus]}
      </Text>
      &nbsp;
      <Text component="span">{renderDateText()}</Text>
    </Box>
  );
};

export const ClaimStatusInfo = memo(ClaimStatusInfoBase);
