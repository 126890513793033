import React, { memo } from 'react';
import { ActionIcon, Flex, Spoiler, Sx, Text, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { addNoteHasReferencesSelector, resetAddNoteState, useNotesStore } from '@/pageAI/states/notes';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';

interface NoteSnippetProps {
  imageUrl?: string | null;
  snippet?: string | null;
  sx?: Sx;
  removable?: boolean;
  isMarkdown?: boolean;
  alwaysExpanded?: boolean;
}

const NoteFileSnippetBase = ({
  imageUrl,
  snippet,
  sx,
  removable = false,
  isMarkdown = false,
  alwaysExpanded = false,
}: NoteSnippetProps) => {
  const hasReferences = useNotesStore(addNoteHasReferencesSelector);
  const isPrinting = useNotesStore((state) => state.isPrinting);

  if (snippet || imageUrl) {
    let content: React.ReactNode = null;

    if (snippet) {
      content = isMarkdown ? <MarkdownRenderer content={snippet} /> : snippet;
    }

    if (imageUrl) {
      content = (
        <Flex
          align="center"
          justify="center"
          my={4}
          sx={(theme) => ({
            width: '100%',
            overflow: 'hidden',
          })}
        >
          <img src={imageUrl} alt="Note screenshot" style={{ maxWidth: 480, maxHeight: 200 }} />
        </Flex>
      );
    }

    return (
      <Flex
        align="center"
        gap="xs"
        pos="relative"
        sx={(theme) => ({
          padding: '4px 8px',
          borderRadius: 4,
          border: isPrinting ? `1px solid ${theme.colors.gray[3]}` : `1px solid ${theme.colors.gray[2]}`,
          background: theme.colors.gray[2],
          transition: 'all 0.1s ease-in-out',
          width: '100%',
          ...sx,
        })}
      >
        {alwaysExpanded ? (
          <>
            {imageUrl ? (
              content
            ) : (
              <Text
                fz="0.75rem"
                sx={{ wordBreak: 'break-word', cursor: 'text', ul: { margin: 0 } }}
                className="normalized-markdown"
              >
                {content}
              </Text>
            )}
          </>
        ) : (
          <Spoiler
            maxHeight={40}
            showLabel="Show more"
            hideLabel="Show less"
            fz="0.75rem"
            className="normalized-markdown"
            sx={(theme) => ({
              cursor: 'text',
              width: '100%',
              '*:not(.ghost-Spoiler-control)': {
                fontSize: '0.75rem',
                wordBreak: 'break-word',
              },
              '.ghost-Spoiler-control': {
                color: theme.colors.dark[4],
                fontWeight: 600,
              },
              ul: {
                margin: 0,
              },
            })}
          >
            {content}
          </Spoiler>
        )}

        {removable && (
          <Tooltip label="Clear references" withinPortal withArrow>
            <ActionIcon size="xs" pos="absolute" right={2} bottom={4} onClick={() => resetAddNoteState()}>
              <IconX size={14} />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    );
  }

  if (hasReferences && !isPrinting) {
    return (
      <Flex justify="end" sx={{ width: '100%' }}>
        <UnderlinedTextButton color="gray.7" sx={{ fontSize: '0.75rem' }} onClick={resetAddNoteState}>
          Clear references
        </UnderlinedTextButton>
      </Flex>
    );
  }

  return null;
};

export const NoteFileSnippet = memo(NoteFileSnippetBase);
