import { memo } from 'react';
import { SegmentedControl } from '@mantine/core';

import {
  setConditionSummaryViewMode,
  summaryTabViewModeSelector,
  ConditionSummaryViewMode,
  useUnifiedStore,
} from '@/pageAI/states/unified';

interface ConditionViewModeTabsProps {}

const ConditionViewModeTabsBase = (props: ConditionViewModeTabsProps) => {
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);

  const handleChangeViewMode = (viewMode: ConditionSummaryViewMode) => {
    setConditionSummaryViewMode(viewMode);
  };

  return (
    <SegmentedControl
      size="xs"
      data={['Table View', 'Chronological']}
      value={viewMode}
      onChange={handleChangeViewMode}
      sx={{
        '.ghost-SegmentedControl-label': {
          width: 96,
        },
      }}
    />
  );
};

export const ConditionViewModeTabs = memo(ConditionViewModeTabsBase);
