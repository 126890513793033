import { memo, useMemo, useState } from 'react';
import { queryToSearchKeywords, searchKeywordsToQuery } from '@/pageAI/services/search';
import { ActionIcon, Chip, Flex, Input, Popover, Text } from '@mantine/core';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { IconCircleCheckFilled, IconSearch, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useLayoutStore } from '@/shared/states/layout';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';
import { setSelectedTerms } from '@/pageAI/states/unified';

interface ContentSearchInputProps {
  query?: string;
  onChange?: (query: string) => void;
  onConfirm?: () => void;
}

const ContentSearchInputBase = ({ query, onChange, onConfirm }: ContentSearchInputProps) => {
  const isRightSidebarOpened = useLayoutStore((state) => state.isRightSidebarOpened);

  const maxKeywords = isRightSidebarOpened ? 2 : 4;

  const [opened, { open, close }] = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const searchKeywords = useMemo(() => queryToSearchKeywords(query || ''), [query]);

  const handleChangeKeywords = (keywords: string[]) => {
    onChange?.(searchKeywordsToQuery([...new Set(keywords)]));

    setSelectedTerms((currentTerms) => {
      return [...new Set([...currentTerms.filter((term) => keywords.includes(term)), ...keywords])];
    });
  };

  const removeKeyword = (keyword: string) => {
    const newKeywords = searchKeywords.filter((item) => item !== keyword);

    handleChangeKeywords(newKeywords);
  };

  const addFromSearchValue = () => {
    setSearchValue('');

    if (!searchValue) return onConfirm?.();

    const newKeywords = [...new Set([...searchKeywords, searchValue])];

    handleChangeKeywords(newKeywords);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') return addFromSearchValue();

    if (event.key === 'Backspace' && !searchValue) {
      const newKeywords = searchKeywords.slice(0, -1);

      handleChangeKeywords(newKeywords);
    }
  };

  const handleClearAll = () => {
    handleChangeKeywords([]);
  };

  const renderChips = () => {
    if (searchKeywords.length === 0) return null;

    const hasMoreThanTwoKeywords = searchKeywords.length > maxKeywords;

    return (
      <Flex gap={4} sx={{ flexWrap: 'wrap' }} mr={4}>
        <Chip.Group value="">
          {searchKeywords.slice(0, maxKeywords).map((keyword) => {
            return (
              <Chip
                key={keyword}
                size="xs"
                variant="light"
                color="dark.4"
                sx={{
                  '.ghost-Chip-label': {
                    paddingLeft: 12,
                    paddingRight: 8,
                  },
                }}
              >
                {keyword}

                <Flex
                  ml={4}
                  sx={(theme) => ({
                    color: theme.colors.gray[5],
                  })}
                  onClick={() => removeKeyword(keyword)}
                >
                  <IconX size={14} />
                </Flex>
              </Chip>
            );
          })}
        </Chip.Group>

        {hasMoreThanTwoKeywords && (
          <Chip size="xs" variant="light" color="dark.4">
            +{searchKeywords.length - maxKeywords}
          </Chip>
        )}
      </Flex>
    );
  };

  const renderActionButtons = () => {
    if (searchKeywords.length === 0) return null;

    return (
      <Flex
        align="center"
        justify="space-between"
        sx={(theme) => ({
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 8,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Flex ml={12}>
          <Text fz="0.75rem" color="dark.4" fw={500}>
            {searchKeywords.length} keyword{searchKeywords.length > 1 ? 's' : ''} selected
          </Text>
        </Flex>

        <Flex align="center">
          <UnderlinedTextButton onClick={handleClearAll} sx={{ fontSize: '0.75rem' }}>
            Clear all
          </UnderlinedTextButton>
        </Flex>
      </Flex>
    );
  };

  const forceCloseDropdown = !searchValue && searchKeywords.length === 0;

  return (
    <Popover
      {...(forceCloseDropdown ? { opened: false } : { opened })}
      width="target"
      withinPortal
      zIndex={101}
      shadow="sm"
    >
      <Popover.Target>
        <Flex
          align="center"
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 4,
            height: 36,
            padding: '0 4px 0 8px',
            minWidth: 320,
          })}
          onFocus={open}
          onBlur={close}
        >
          {renderChips()}

          <Input
            sx={{
              input: {
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                padding: '0 4px 0 0',
                width: '100%',
                flexGrow: 1,
                fontSize: '0.75rem',
                lineHeight: 1,
                color: 'dark',
                background: 'none',
              },
              flexGrow: 1,
            }}
            value={searchValue}
            placeholder="Add a keyword..."
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyDown={handleKeyUp}
          />

          <ActionIcon variant="filled" color="dark" onClick={onConfirm}>
            <IconSearch size={16} />
          </ActionIcon>
        </Flex>
      </Popover.Target>

      <Popover.Dropdown p={4}>
        <Flex direction="column">
          {searchValue ? (
            <MenuItem onClick={addFromSearchValue} sx={{ width: '100%' }}>
              <Text
                fz="0.75rem"
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                + Add &quot;{searchValue}&quot;
              </Text>
              <Text
                ml="xs"
                component="strong"
                color="white"
                fz="0.625rem"
                sx={(theme) => ({
                  padding: '2px 6px',
                  background: theme.fn.lighten(theme.colors.dark[5], 0.1),
                  borderRadius: 4,
                })}
              >
                Enter
              </Text>
            </MenuItem>
          ) : null}

          {renderActionButtons()}

          {searchKeywords.map((keyword) => {
            return (
              <MenuItem
                key={keyword}
                onClick={() => removeKeyword(keyword)}
                rightSection={
                  <Flex align="center" justify="center" sx={{ minWidth: 16, minHeight: 16 }}>
                    <IconCircleCheckFilled size={16} />
                  </Flex>
                }
              >
                <Text fz="0.75rem">{keyword}</Text>
              </MenuItem>
            );
          })}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const ContentSearchInput = memo(ContentSearchInputBase);
