import { memo } from 'react';
import { Flex, Text, ThemeIcon, createStyles } from '@mantine/core';

import { TimelineEventGroupProps } from './TimelineEventGroup.types';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { getTimelineEventElementId, getYearFromEventDateString } from '@/pageAI/services/caseTimeline';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { ReadonlyTimelineEvent } from '../ReadonlyTimelineEvent';
import { CaseTimelineItemView } from '../../summaries/CaseTimelineItemView';
import { IconCalendarEvent } from '@tabler/icons-react';

export const useStyles = createStyles((theme) => ({
  unorderedList: {
    margin: 0,
    '&:not:first-of-type': {
      marginTop: 24,
    },
    '> li': {
      marginTop: 8,
    },
  },
}));

const TimelineEventGroupBase = ({ date, events, readonly = false, isFirst = false }: TimelineEventGroupProps) => {
  const { classes } = useStyles();

  const { keywords, prefix } = useCaseTimelineContext();
  const client = useSelectedClient(true);

  const year = getYearFromEventDateString(date);

  return (
    <Flex direction="column">
      {isFirst ? (
        <Flex align="center" gap={6} mt="xs">
          <ThemeIcon radius="50%" size={24}>
            <IconCalendarEvent size={12} />
          </ThemeIcon>

          <Text fw={600} color="dark.4">
            {year}
          </Text>
        </Flex>
      ) : null}

      <ul style={{ margin: 0, paddingLeft: 48 }}>
        <li key={[prefix, date].join('')} style={{ marginBottom: 8 }}>
          <Text color="dark.4" fz="sm" fw={600} component="span">
            {events[0].formattedDate}:
          </Text>
          <ul className={classes.unorderedList}>
            {events.map((event, index) => {
              return (
                <li key={[prefix, event.id].join('')} id={getTimelineEventElementId(event.id, prefix)}>
                  {readonly ? (
                    <ReadonlyTimelineEvent event={event} keywords={keywords} />
                  ) : (
                    <CaseTimelineItemView client={client} item={event} keywords={keywords} hideDate />
                  )}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </Flex>
  );
};

export const TimelineEventGroup = memo(TimelineEventGroupBase);
