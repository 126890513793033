import { memo } from 'react';
import { Flex, Text, createStyles } from '@mantine/core';

import { CaseTimelineItemViewsProps } from './CaseTimelineItemViews.types';
import { CaseTimelineItemView } from '../CaseTimelineItemView/CaseTimelineItemView';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { getTimelineEventElementId } from '@/pageAI/services/caseTimeline';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { ReadonlyTimelineEvent } from '../../timeline/ReadonlyTimelineEvent';

export const useStyles = createStyles((theme) => ({
  unorderedList: {
    margin: 0,
    '&:not:first-of-type': {
      marginTop: 24,
    },
    '> li': {
      marginTop: 8,
    },
  },
}));

const CaseTimelineItemViewsBase = ({ caseTimelineItems, readonly = false }: CaseTimelineItemViewsProps) => {
  const { classes } = useStyles();

  const { keywords, prefix } = useCaseTimelineContext();
  const client = useSelectedClient(true);

  const entries = Object.entries(caseTimelineItems);

  return (
    <Flex direction="column">
      <ul style={{ margin: 0 }}>
        {entries.map(([date, events]) => {
          return (
            <li key={[prefix, date].join('')} style={{ marginBottom: 8 }}>
              <Text color="dark.4" fz="sm" fw={600} component="span">
                {events[0].formattedDate}:
              </Text>
              <ul className={classes.unorderedList}>
                {events.map((event, index) => {
                  return (
                    <li key={[prefix, event.id].join('')} id={getTimelineEventElementId(event.id, prefix)}>
                      {readonly ? (
                        <ReadonlyTimelineEvent event={event} keywords={keywords} />
                      ) : (
                        <CaseTimelineItemView client={client} item={event} keywords={keywords} hideDate />
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </Flex>
  );
};

export const CaseTimelineItemViews = memo(CaseTimelineItemViewsBase);
