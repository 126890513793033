import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Flex } from '@mantine/core';

import {
  ConditionSummaryViewMode,
  setShowConditionSummaryNullFields,
  setSummaryIsPrinting,
  setConditionSummaryViewMode,
} from '@/pageAI/states/unified';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { PrintableConditionSummary, PrintableConditionSummaryInstance } from '../PrintableConditionSummary';
import { useConditionRequest } from '@/pageAI/hooks/conditions/useCondition';
import { Client } from '@/pageAI/@types';
import { useCFileOrder } from '@/pageAI/hooks/export/useCFileOrder';

interface ExportConditionsButtonProps {
  client: Client;
  viewMode?: ConditionSummaryViewMode;
  hideNAFields?: boolean;
  cFileIds?: string[];
}

const ExportConditionsLayoutBase = ({
  client,
  cFileIds,
  viewMode = 'Table View',
  hideNAFields = true,
}: ExportConditionsButtonProps) => {
  const { fetchCondition } = useConditionRequest();

  useCFileOrder(client, cFileIds);

  const [isFetching, setIsFetching] = useState(true);
  const printableConditionSummaryInstance = useRef<PrintableConditionSummaryInstance>(null);

  const startFetching = useCallback(async () => {
    setIsFetching(true);

    const conditionIdsToExport = window.serverInjection?.conditionSummaryExport?.conditionIds;

    if (!conditionIdsToExport?.length) throw new Error('No conditions to export');

    for (const conditionId of conditionIdsToExport) {
      const fullCondition = await fetchCondition(conditionId);

      if (!fullCondition) continue;

      await printableConditionSummaryInstance.current?.printCondition(fullCondition);
    }

    printableConditionSummaryInstance.current?.finish();

    setIsFetching(false);
  }, [fetchCondition]);

  const startExporting = useCallback(async () => {
    setSummaryIsPrinting(true);

    await startFetching();

    requestIdleCallback(() => {
      setSummaryIsPrinting(false);
    });
  }, [startFetching]);

  useEffect(() => {
    startExporting();
  }, [startExporting]);

  useLayoutEffect(() => {
    setConditionSummaryViewMode(viewMode);
    setShowConditionSummaryNullFields(!hideNAFields);
  }, [viewMode, hideNAFields]);

  return (
    <Box
      className="printable"
      sx={{
        background: 'white',
        height: 'fit-content',
        minHeight: '100vh',
        ul: {
          paddingLeft: 24,
        },
      }}
    >
      <PrintableConditionSummary ref={printableConditionSummaryInstance} />

      {isFetching && (
        <Flex
          align="center"
          justify="center"
          sx={(theme) => ({ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, background: theme.white })}
        >
          <LoadingPlaceholder
            title="Exporting condition summary..."
            description="Please wait while your condition summary is being exported..."
          />
        </Flex>
      )}
    </Box>
  );
};

export const ExportConditionsLayout = memo(ExportConditionsLayoutBase);
