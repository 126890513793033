import memoize from 'memoize';

import { Client, ClientItem } from '@/pageAI/@types';
import { CollectionAsset, FileAsset } from '@/shared/@types';
import { normalizeFileCollection } from '@/shared/services/files';
import { getFullName } from '@/shared/utils/name';

import PASSED_AWAY_CLIENT_FILES from './passedAwayClientFiles.json';
import { removeAllSpaces } from '@/shared/utils/string';

export type ClientTab = 'Organization' | 'Assigned to me' | 'Shared with me';

export const getClientDisplayName = (client: Pick<ClientItem, 'firstName' | 'lastName'>) => {
  return getFullName(client.firstName, client.lastName).trim();
};

export const getClientDetailsUrl = (clientId: string) => `/clients/${clientId}`;

export const getClientFiles = memoize((client: Pick<Client, 'fileCollections'>) => {
  const mainFileCollectionAsset = normalizeFileCollection(client.fileCollections.nodes[0]);

  const fileAssets = mainFileCollectionAsset.files || [];

  const fileAssetMapping = getFileAssetMapping(fileAssets);

  return { mainFileCollectionAsset, fileAssets, fileAssetMapping };
}) as (client: Pick<Client, 'fileCollections'>) => {
  mainFileCollectionAsset: CollectionAsset;
  fileAssets: FileAsset[];
  fileAssetMapping: Record<string, FileAsset | undefined>;
};

export const getFileAssetMapping = memoize((fileAssets: FileAsset[]) => {
  const fileAssetMapping: Record<string, FileAsset | undefined> = {};

  fileAssets.forEach((fileAsset) => {
    fileAssetMapping[fileAsset.id] = fileAsset;
  });

  return fileAssetMapping;
}) as (fileAssets: FileAsset[]) => Record<string, FileAsset | undefined>;

export const quickFindFileAsset = (fileAssets: FileAsset[], fileId: string) => {
  const fileAssetMapping = getFileAssetMapping(fileAssets);

  return fileAssetMapping[fileId];
};

export const quickFindClientFile = (client: Pick<Client, 'fileCollections'>, fileId: string) => {
  const { fileAssetMapping } = getClientFiles(client);

  return fileAssetMapping[fileId];
};

export const isPassedAwayClients = (client: Client) => {
  const { fileAssets } = getClientFiles(client);

  return fileAssets.some((fileAsset) => PASSED_AWAY_CLIENT_FILES.includes(fileAsset.id));
};

const formatExportFileName = (baseName: string, suffix: string, extension: string, date: Date) => {
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}${date
    .getDate()
    .toString()
    .padStart(2, '0')}${date.getFullYear()}`;

  return `${removeAllSpaces(baseName)}_${suffix}_${formattedDate}${extension}`;
};

export const getClientMergedCFileName = (client: Client, date = new Date()) => {
  const baseName = getClientDisplayName(client);

  return formatExportFileName(baseName, 'FullCFile', '.pdf', date);
};

export const getExportedConditionSummaryFileName = (client: Client, extension = '.pdf', date = new Date()) => {
  const baseName = getClientDisplayName(client);

  return formatExportFileName(baseName, 'ConditionSummary', extension, date);
};

export const getExportedTimelineFileName = (client: Client, extension = '.pdf', date = new Date()) => {
  const baseName = getClientDisplayName(client);

  return formatExportFileName(baseName, 'CaseTimeline', extension, date);
};

export const getExportedNotesFileName = (client: Client, extension = '.pdf', date = new Date()) => {
  const baseName = getClientDisplayName(client);

  return formatExportFileName(baseName, 'Notes', extension, date);
};
