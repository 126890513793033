import { forwardRef, memo, useEffect, useRef } from 'react';
import { Flex } from '@mantine/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { InlineEditor as CKInlineEditor } from '@ckeditor/ckeditor5-editor-inline';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Code, Strikethrough } from '@ckeditor/ckeditor5-basic-styles';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';

import { InlineEditorProps } from './InlineEditor.types';

const InlineEditorBase = (
  { data, toolbar, readonly, sx, autofocus, onDataChange, onReady }: InlineEditorProps,
  ref: React.ForwardedRef<CKEditor<CKInlineEditor>>,
) => {
  const editorRef = useRef<CKInlineEditor | null>(null);

  useEffect(() => {
    if (!editorRef.current) return;

    if (readonly) {
      editorRef.current.enableReadOnlyMode('readonly');
    } else {
      editorRef.current.disableReadOnlyMode('readonly');
    }
  }, [readonly]);

  useEffect(() => {
    if (autofocus) {
      requestIdleCallback(() => editorRef.current?.editing.view.focus());
    }
  }, [autofocus]);

  return (
    <Flex
      sx={{
        fontSize: '0.875rem',
        width: '100%',
        '.ck-editor__editable': { cursor: 'text', '> p:first-of-type': { margin: 0 } },
        '.ck-file-dialog-button': { display: 'none' },
        ...sx,
      }}
    >
      <CKEditor
        ref={ref}
        editor={CKInlineEditor}
        data={data}
        onReady={(editor) => {
          editorRef.current = editor;

          if (readonly) editor.enableReadOnlyMode('readonly');

          onReady?.(editor);
        }}
        config={{
          plugins: [
            Autoformat,
            Markdown,
            Code,
            CodeBlock,
            Strikethrough,
            SourceEditing,
            Essentials,
            Bold,
            Italic,
            BlockQuote,
            Heading,
            Link,
            List,
            Paragraph,
          ],
          toolbar: toolbar ?? [
            'heading',
            'bold',
            'italic',
            '|',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            '|',
            'sourceEditing',
          ],
        }}
        onChange={(event, editor) => {
          if (!onDataChange) return;

          onDataChange(editor.getData());
        }}
      />
    </Flex>
  );
};

export const InlineEditor = memo(forwardRef(InlineEditorBase));
