import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Client } from '@/pageAI/@types';
import { showAsyncNotification } from '@/shared/services/notifications';
import { ConditionSummaryViewMode } from '@/pageAI/states/unified';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import {
  ClientExportJobStatus,
  ClientExportJobType,
  ExportConditionSummaryInput,
  ExportConditionSummaryMutation,
} from '@/pageAI/gql/graphql';
import { exportConditionSummaryMutation } from '@/pageAI/api/export';
import { FetchStatus } from '@/shared/@types';
import { getExportNotificationContent, pollExportJob } from '@/pageAI/services/export';
import { queryMap } from '../useLastExportJob';
import { downloadFromUrl } from '@/shared/lib/browser';
import { getClientMergedCFileName, getExportedConditionSummaryFileName } from '@/pageAI/services/clients';

interface ExportConditionSummaryProps {
  client: Client;
  conditionIds: string[];
  viewMode?: ConditionSummaryViewMode;
  hideNAFields?: boolean;
  withCFile?: boolean;
}

export const useExportConditionSummary = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutateAsync: exportConditionSummaryAsync } = useMutation<
    ExportConditionSummaryMutation,
    Error,
    ExportConditionSummaryInput
  >({
    mutationFn: async (input) => {
      return request(exportConditionSummaryMutation, { input });
    },
  });

  const exportConditionSummary = useCallback(
    async ({
      client,
      conditionIds,
      viewMode = 'Table View',
      hideNAFields = true,
      withCFile = false,
    }: ExportConditionSummaryProps) => {
      const { title: loadingTitle, description: loadingDescription } = getExportNotificationContent({
        client,
        status: FetchStatus.LOADING,
        withMergedCFile: withCFile,
      });

      const { onSuccess, onError, close } = showAsyncNotification(loadingTitle, loadingDescription, {
        withoutIcon: true,
      });

      try {
        const {
          exportConditionSummary: { exportJob },
        } = await exportConditionSummaryAsync({
          clientId: client.id,
          includeCFiles: withCFile,
          conditionIds,
          viewMode,
          hideNAFields,
        });

        const finishedExportJob = await pollExportJob(request)(exportJob.id);

        if (finishedExportJob.status === ClientExportJobStatus.Failed)
          throw new Error('Failed to export condition summary');

        if (finishedExportJob.downloadUrl)
          downloadFromUrl(finishedExportJob.downloadUrl, getExportedConditionSummaryFileName(client));

        if (finishedExportJob.cFileDownloadUrl)
          downloadFromUrl(finishedExportJob.cFileDownloadUrl, getClientMergedCFileName(client));

        const { title: successTitle, description: successDescription } = getExportNotificationContent({
          client,
          status: FetchStatus.SUCCESS,
          withMergedCFile: withCFile,
          exportDownloadUrl: finishedExportJob.downloadUrl,
          mergedCFileDownloadUrl: finishedExportJob.cFileDownloadUrl,
          onClose: close,
        });

        onSuccess(successTitle, successDescription, {
          withoutIcon: true,
          autoClose: false,
          withCloseButton: false,
        });
      } catch (error) {
        console.error(error);

        const { title: errorTitle, description: errorDescription } = getExportNotificationContent({
          client,
          status: FetchStatus.ERROR,
        });

        onError(errorTitle, errorDescription, { withoutIcon: true, autoClose: false });
      }

      queryClient.refetchQueries(
        queryMap.client.lastExportJob.queryKey(client.id, ClientExportJobType.ConditionSummary),
      );
    },
    [request, exportConditionSummaryAsync, queryClient],
  );

  return { exportConditionSummary };
};
