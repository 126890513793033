import { memo, useEffect } from 'react';
import { Box, Flex, ScrollArea } from '@mantine/core';

import { MedicalConditionItemAsSummary } from '../../medicalConditions/MedicalConditionItemAsSummary';
import { selectedSimpleConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { MedicalConditionTitle } from '../../medicalConditions/MedicalConditionTitle';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { ExportConditionsButton } from '../../medicalConditions/export/ExportConditionsButton';
import { useElementRect } from '@/shared/hooks/dom/useElementRect';
import { useConditionSearchTerms } from '@/pageAI/hooks/conditions/useConditionSearchTerms';
import { useCondition } from '@/pageAI/hooks/conditions/useCondition';
import { useConditionSharing } from './ConditionSummaryContainer.utils';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { DataEmptyPlaceholder } from '../../clients/DataEmptyPlaceholder';
import { IconTemplateOff } from '@tabler/icons-react';

const ConditionSummaryContainerBase = () => {
  const {
    getConditionSearchTerms,
    isLoading: isLoadingConditionSearchTerms,
    isError: isErrorConditionSearchTerms,
  } = useConditionSearchTerms();

  const selectedSimpleCondition = useUnifiedStore(selectedSimpleConditionSelector);
  const { ref: headerRef, rect: headerRect } = useElementRect();

  const { condition, isLoading, isError } = useCondition({ conditionId: selectedSimpleCondition?.id });

  useConditionSharing({ selectedMedicalConditionId: condition?.id, isLoading });

  useEffect(() => {
    if (!condition || isLoadingConditionSearchTerms || isErrorConditionSearchTerms) return;

    getConditionSearchTerms(condition);
  }, [condition, isLoadingConditionSearchTerms, isErrorConditionSearchTerms, getConditionSearchTerms]);

  const headerHeight = headerRect.top + headerRect.height;

  const renderBody = () => {
    if (isLoading) {
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: `calc(100vh - ${headerHeight}px)` }}>
          <LoadingPlaceholder
            title="Loading condition summary..."
            description="Please wait while Page retrieves the condition summary."
            fullScreen
          />
        </Flex>
      );
    }

    if (isError)
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: `calc(100vh - ${headerHeight}px)` }}>
          <ErrorPlaceholder />
        </Flex>
      );

    if (!condition)
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: `calc(100vh - ${headerHeight}px)` }}>
          <DataEmptyPlaceholder
            icon={<IconTemplateOff size={80} />}
            title="Condition not found"
            description="No info about this condition was found. Please select another condition."
          />
        </Flex>
      );

    return (
      <>
        <ScrollArea
          id="condition-summary-scroll-area"
          sx={{
            height: `calc(100vh - ${headerHeight}px)`,
            code: {
              fontFamily: 'unset',
              textDecoration: 'underline',
              fontWeight: 500,
            },
            '.ghost-ScrollArea-viewport': {
              '> div': {
                height: '100%',
              },
            },
          }}
        >
          <Box
            sx={{
              padding: '16px 32px',
            }}
          >
            <MedicalConditionItemAsSummary medicalCondition={condition} />
          </Box>
        </ScrollArea>

        <ExportConditionsButton />
      </>
    );
  };

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        ref={headerRef}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          padding: '12px 32px',
        })}
      >
        <MedicalConditionTitle />
      </Flex>

      {renderBody()}
    </Flex>
  );
};

export const ConditionSummaryContainer = memo(ConditionSummaryContainerBase);
