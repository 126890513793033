import { memo, useCallback, useMemo } from 'react';
import { useContextMenu } from 'mantine-contextmenu';
import { ActionIcon, Box, clsx, Flex, Text, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconArrowBack, IconShare, IconTrashX } from '@tabler/icons-react';

import { MedicalConditionSummaryEntryProps } from './MedicalConditionSummaryEntry.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { HighlightType, highlightKeywordsInMarkdown } from '@/shared/utils/markdown';
import { SummaryFileReferenceBadge } from '../../summaries/SummaryFileReferenceBadge';
import { appendSearchParams } from '@/shared/utils/url';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import {
  getDocHintTypeFromEntryKey,
  getFormattedConditionSummaryEntryDate,
  getMedicalConditionEntryId,
} from '@/pageAI/services/medicalConditions';
import { getClientFiles } from '@/pageAI/services/clients';
import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { useManageConditionFiles } from '@/pageAI/hooks/conditions/useManageConditionFiles';
import { posthog } from '@/shared/plugins/posthog';
import { isPrintingConditionSummarySelector, useUnifiedStore } from '@/pageAI/states/unified';
import { useDismissHintsForCondition } from '@/pageAI/hooks/conditions/useDismissHintsForCondition';
import { truthy } from '@/shared/utils/boolean';
import { takeNoteFromConditionEntry } from '@/pageAI/services/notes';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';

const MedicalConditionSummaryEntryBase = ({
  medicalCondition,
  entry,
  entryKey,
  sectionField,
  keywords = [],
  isHighlighted = false,
  onReferenceClick,
}: MedicalConditionSummaryEntryProps) => {
  const { notify } = useNotifications();
  const showContextMenu = useContextMenu();

  const client = useSelectedClient(true);
  const { removeFilesFromCondition, isRemoving } = useManageConditionFiles(client.id);
  const { undoDismissHintsForCondition } = useDismissHintsForCondition();

  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);

  const referenceWithIndex = useMemo(() => {
    if (!entry.reference) return null;

    return addFileIndexToEventReference(entry.reference, fileAssets);
  }, [entry.reference, fileAssets]);

  const restorableMissingHintType = useMemo(() => getDocHintTypeFromEntryKey(entryKey), [entryKey]);

  const handleTakeNote = useCallback(() => {
    takeNoteFromConditionEntry({ client, condition: medicalCondition, entry, entryKey, sectionField });
  }, [client, entry, sectionField, entryKey, medicalCondition]);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      return showContextMenu(
        [
          {
            key: 'share',
            icon: <IconShare size={16} />,
            title: 'Share',
            onClick: () => {
              posthog.capture('[Conditions] Share condition entry', {
                clientId: client.id,
                clientFirstName: client.firstName,
                clientLastName: client.lastName,
                conditionId: medicalCondition.id,
                conditionHeader: medicalCondition.headerCondition,
                entryKey,
                entryFileId: entry.fileId,
                entryReference: entry.reference,
              });

              const searchParams = new URLSearchParams(window.location.search);

              searchParams.set('medicalConditionId', medicalCondition.id);
              searchParams.set('entryKey', entryKey);

              navigator.clipboard.writeText(appendSearchParams(window.location.href, searchParams));

              notify('Copied', 'Entry link copied', 'brand');
            },
          },
          restorableMissingHintType
            ? {
                key: 'undo-dismiss',
                icon: <IconArrowBack size={16} />,
                title: 'Restore hints',
                onClick: () => {
                  undoDismissHintsForCondition({
                    conditionId: medicalCondition.id,
                    hintTypes: [restorableMissingHintType],
                  });
                },
              }
            : null,
          {
            key: 'take-note',
            icon: <IconNotePlus width={16} height={16} />,
            title: 'Take note',
            onClick: handleTakeNote,
          },
          {
            key: 'remove',
            color: 'red',
            icon: <IconTrashX size={16} />,
            title: 'Remove',
            onClick: () => {
              modals.openConfirmModal({
                title: 'Remove entry',
                children: (
                  <Text fz="0.875rem">
                    Are you sure you want to remove this entry from the condition? This action cannot be undone.
                  </Text>
                ),
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                confirmProps: {
                  color: 'red',
                },
                cancelProps: {
                  variant: 'subtle',
                  color: 'gray.7',
                },
                onConfirm: () => {
                  if (!entry.fileId) return;

                  posthog.capture('[Conditions] Remove condition entry', {
                    clientId: client.id,
                    clientFirstName: client.firstName,
                    clientLastName: client.lastName,
                    conditionId: medicalCondition.id,
                    conditionHeader: medicalCondition.headerCondition,
                    entryKey,
                    entryFileId: entry.fileId,
                    entryReference: entry.reference,
                  });

                  removeFilesFromCondition(medicalCondition.id, [entry.fileId]);
                },
              });
            },
          },
        ].filter(truthy),
        { sx: { minWidth: 164 } },
      )(event);
    },
    [
      showContextMenu,
      notify,
      medicalCondition,
      entryKey,
      entry,
      removeFilesFromCondition,
      client,
      undoDismissHintsForCondition,
      restorableMissingHintType,
      handleTakeNote,
    ],
  );

  const summaries = useMemo(() => {
    const separator = '\n  - ';
    const separatorIndex = entry.summary.indexOf(separator);

    if (separatorIndex < 0) return [entry.summary];

    return [entry.summary.slice(0, separatorIndex), entry.summary.slice(separatorIndex)];
  }, [entry.summary]);

  const renderActions = () => {
    return (
      <Flex
        sx={{ display: 'inline-flex', position: 'relative', width: 1, height: 18 }}
        align="center"
        justify="center"
        className={clsx('actions', 'unprintable')}
      >
        <Tooltip label="Take note" withinPortal withArrow>
          <ActionIcon size="xs" color="gray.7" sx={{ position: 'absolute', left: 4, top: 4 }} onClick={handleTakeNote}>
            <IconNotePlus width={16} height={16} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    );
  };

  const renderContent = () => {
    const referenceBadge = referenceWithIndex ? (
      <SummaryFileReferenceBadge
        client={client}
        isPrinting={isPrinting}
        isHighlighted={isHighlighted}
        onClick={() => onReferenceClick?.(entry, entryKey)}
        reference={referenceWithIndex}
      />
    ) : null;

    if (summaries.length === 1) {
      return (
        <>
          <MarkdownRenderer content={highlightKeywordsInMarkdown(entry.summary, keywords, HighlightType.LIGHTER)} />

          {referenceBadge}

          {renderActions()}
        </>
      );
    }

    return (
      <>
        <MarkdownRenderer content={highlightKeywordsInMarkdown(summaries[0], keywords, HighlightType.LIGHTER)} />

        {referenceBadge}

        {renderActions()}

        <MarkdownRenderer content={highlightKeywordsInMarkdown(summaries[1], keywords, HighlightType.LIGHTER)} />
      </>
    );
  };

  return (
    <>
      <Box
        id={getMedicalConditionEntryId(medicalCondition.id, entryKey)}
        onContextMenu={handleContextMenu}
        sx={{
          fontSize: '0.875rem',
          width: '100%',
          p: {
            margin: 0,
            display: 'inline',
          },
          '.ghost-Badge-root': {
            marginLeft: 6,
          },
          '.actions': {
            opacity: 0,
            pointerEvents: 'none',
            transition: 'all 0.15s ease-in-out',
          },
          '&:hover .actions': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        }}
        data-file-id={entry.fileId}
      >
        {entry.summary !== 'N/A' ? (
          <Text component="span">
            {getFormattedConditionSummaryEntryDate(entry)}
            {entry.summary ? ':' : null}
            &nbsp;
          </Text>
        ) : null}

        {renderContent()}
      </Box>
    </>
  );
};

export const MedicalConditionSummaryEntry = memo(MedicalConditionSummaryEntryBase);
