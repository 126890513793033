import { memo, useEffect, useState } from 'react';
import { Chip, Flex } from '@mantine/core';

import { ConditionSectionKey } from '@/pageAI/@types';
import { CONDITION_SECTION_TEXT_MAPPING } from '@/pageAI/services/medicalConditions';
import { isPrintingConditionSummarySelector, useUnifiedStore } from '@/pageAI/states/unified';

interface ConditionSectionBadgesProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  onClick?: (section: ConditionSectionKey) => void;
  sections?: ConditionSectionKey[];
}

const ConditionSectionBadgesBase = ({
  value,
  onChange,
  onClick,
  sections = ['proceduralHistory', 'medicalEvidence', 'otherEvidence'],
}: ConditionSectionBadgesProps) => {
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);
  const [currentSectionInView, setCurrentSectionInView] = useState<ConditionSectionKey | null>(null);

  useEffect(() => {
    if (onChange || isPrinting) return;

    const conditionSummaryScrollAreaElement = document.getElementById('condition-summary-scroll-area');

    if (!conditionSummaryScrollAreaElement) return;

    const scrollAreaViewportElement = conditionSummaryScrollAreaElement.querySelector(
      '.ghost-ScrollArea-viewport',
    ) as HTMLDivElement | null;

    if (!scrollAreaViewportElement) return;

    const handleScroll = () => {
      const sectionKeys = ['proceduralHistory', 'medicalEvidence', 'otherEvidence'] as ConditionSectionKey[];

      const isAboveSection = (nextSectionKey: ConditionSectionKey) => {
        const nextSectionHeaderElement = document.querySelector(
          `[data-section="${nextSectionKey}"]`,
        ) as HTMLDivElement | null;

        if (!nextSectionHeaderElement) return;

        return scrollAreaViewportElement.scrollTop < nextSectionHeaderElement.offsetTop - 80;
      };

      for (let sectionIndex = 0; sectionIndex < sectionKeys.length - 1; sectionIndex++) {
        if (isAboveSection(sectionKeys[sectionIndex + 1])) {
          setCurrentSectionInView(sectionKeys[sectionIndex]);

          return;
        }
      }

      setCurrentSectionInView(sections.at(-1) || 'otherEvidence');
    };

    handleScroll();

    scrollAreaViewportElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollAreaViewportElement.removeEventListener('scroll', handleScroll);
    };
  }, [onChange, sections, isPrinting]);

  const renderSectionChip = (section: ConditionSectionKey) => (
    <Chip
      key={section}
      variant="filled"
      size="xs"
      onClick={() => {
        onClick?.(section);

        setTimeout(() => setCurrentSectionInView(section));
      }}
      value={section}
    >
      {CONDITION_SECTION_TEXT_MAPPING[section]}
    </Chip>
  );

  if (isPrinting) return null;

  return (
    <Flex
      align="center"
      columnGap="xs"
      rowGap={4}
      sx={{
        ...(onChange
          ? {}
          : {
              'label.ghost-Chip-label': {
                padding: '0 16px',
              },
              '.ghost-Chip-iconWrapper': {
                display: 'none',
              },
            }),
      }}
    >
      <Chip.Group
        {...(onChange
          ? {
              value,
              onChange,
              multiple: true,
            }
          : {
              ...(currentSectionInView ? { value: [currentSectionInView] } : {}),
            })}
      >
        {sections.map(renderSectionChip)}
      </Chip.Group>
    </Flex>
  );
};

export const ConditionSectionBadges = memo(ConditionSectionBadgesBase);
