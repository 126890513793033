import { useMemo } from 'react';

import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useConditionSummaryDeltas } from '../../useClientMedicalConditions';
import { conditionsSelector, isReviewingIncrementalChangesSelector, useUnifiedStore } from '@/pageAI/states/unified';
import {
  doesUpdatedConditionNeedReview,
  getDeltaByConditionMapping,
  getNewConditions,
} from '@/pageAI/services/medicalConditions/conditionVersionReview.services';
import { sortConditionsAlphebetically } from '@/pageAI/services/medicalConditions';

export const useConditionReview = () => {
  const client = useSelectedClient(true);
  const conditions = useUnifiedStore(conditionsSelector);
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);

  const { conditionSummaryDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const deltaByConditionId = useMemo(
    () => (isReviewing ? getDeltaByConditionMapping(conditionSummaryDeltas) : {}),
    [conditionSummaryDeltas, isReviewing],
  );

  const newConditions = useMemo(
    () => (deltaByConditionId && isReviewing ? getNewConditions(conditions, deltaByConditionId) : []),
    [deltaByConditionId, conditions, isReviewing],
  );

  const newConditionIds = useMemo(() => newConditions?.map((condition) => condition.id) || [], [newConditions]);

  const conditionsNeedingReview = useMemo(
    () =>
      deltaByConditionId
        ? conditions?.filter((condition) => doesUpdatedConditionNeedReview(condition, deltaByConditionId))
        : [],
    [conditions, deltaByConditionId],
  );

  const conditionIdsNeedingReview = useMemo(
    () => conditionsNeedingReview?.map((condition) => condition.id) || [],
    [conditionsNeedingReview],
  );

  const updatedConditions = useMemo(
    () =>
      sortConditionsAlphebetically(
        conditionsNeedingReview.filter((condition) => !newConditionIds.includes(condition.id)),
      ),
    [conditionsNeedingReview, newConditionIds],
  );

  return {
    conditionsNeedingReview,
    conditionIdsNeedingReview,
    newConditions,
    newConditionIds,
    updatedConditions,
  };
};
