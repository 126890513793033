import { Fragment, memo } from 'react';
import { Anchor, Badge, Button, Flex, Skeleton, Table, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { capitalize } from 'lodash-es';

import { Organization } from '@/pageAI/@types';
import { useInvoices } from '@/pageAI/hooks/billing/useInvoices';
import { Invoice } from '@/pageAI/gql/graphql';
import { formatDateTime } from '@/shared/utils/date';
import { formatCurrency } from '@/shared/utils/string';

interface BillingLayoutProps {
  organization: Organization;
}

const InvoicesTableBase = ({ organization }: BillingLayoutProps) => {
  const { invoices, isLoading, isError } = useInvoices({ organizationId: organization.id });

  const renderLoadingRows = () => {
    return (
      <Fragment>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr key={index}>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
          </tr>
        ))}
      </Fragment>
    );
  };

  const renderInvoiceRow = (invoice: Invoice) => {
    const description = invoice.description || capitalize(invoice.collectionMethod).replace('_', ' ');

    return (
      <tr>
        <td className="invoices-table__date">{formatDateTime(invoice.createdAt)}</td>
        <td className="invoices-table__id">{invoice.number}</td>
        <td className="invoices-table__amount">
          <Text fw={500}>{formatCurrency(invoice.total / 100)}</Text>
        </td>
        <td className="invoices-table__status">
          <Badge color={invoice.status === 'paid' ? 'teal.6' : 'orange.6'} variant="outline">
            {invoice.status}
          </Badge>
        </td>
        <td className="invoices-table__description">{description}</td>
        <td className="invoices-table__actions">
          <Flex align="center" justify="end">
            <Anchor target="_blank" href={invoice.hostedUrl || ''} rel="noopener noreferrer">
              <Button size="xs" variant="subtle" color="gray.7" disabled={!invoice.hostedUrl}>
                View details
              </Button>
            </Anchor>
          </Flex>
        </td>
      </tr>
    );
  };

  const renderUsageTableBody = () => {
    if (isLoading) return <tbody>{renderLoadingRows()}</tbody>;

    if (isError) {
      return (
        <tbody>
          <Flex align="center" gap={4} p="xs" sx={(theme) => ({ color: theme.colors.red[6] })}>
            <IconAlertCircle size={16} />

            <Text fz="0.875rem" fw={500}>
              Failed to load your organization&apos;s usage history.
            </Text>
          </Flex>
        </tbody>
      );
    }

    if (!invoices?.length) {
      return (
        <tbody>
          <Flex direction="column" justify="center" p="xs">
            <Text fz="0.875rem" color="gray.7">
              Your organization has no invoices yet.
            </Text>
          </Flex>
        </tbody>
      );
    }

    return (
      <tbody>
        {invoices.map((invoice) => {
          return <Fragment key={invoice.id}>{renderInvoiceRow(invoice)}</Fragment>;
        })}
      </tbody>
    );
  };

  const invoicesTable = (
    <Table verticalSpacing="sm" data-testid="invoices-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>ID</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>

      {renderUsageTableBody()}
    </Table>
  );

  return (
    <Flex direction="column" gap="sm">
      <Flex gap="xs" align="center" justify="space-between">
        <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
          Invoices
        </Text>
      </Flex>

      {invoicesTable}
    </Flex>
  );
};

export const InvoicesTable = memo(InvoicesTableBase);
