export const downloadContentAsFile = (blob: Blob, filename?: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;

  if (filename) a.download = filename;

  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadTextAsFile = (text: string, fileName: string) => {
  // Create a Blob containing the text
  const blob = new Blob([text], { type: 'text/plain' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a download link
  const a = document.createElement('a');
  a.href = url;
  a.download = [fileName, '.txt'].join('');

  // Trigger the download
  a.click();

  // Clean up by revoking the temporary URL
  window.URL.revokeObjectURL(url);
};

export const downloadFromUrl = (url: string, fileName: string) => {
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  a.click();
};

export const downloadFromUrlAsync = async (url: string, filename: string) => {
  const response = await fetch(url);

  if (!response.ok) throw new Error(`Could not download from URL ${url}`);

  const blob = await response.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the object URL
  URL.revokeObjectURL(link.href);
};

export const openWindow = (url: string, target?: string) => {
  return window.open(url, target, 'noopener, noreferrer');
};

export const isIframeLoaded = async (iframe: HTMLIFrameElement) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

  return iframeDoc?.readyState == 'complete';
};

export const waitUntilIframeLoaded = async (iframe: HTMLIFrameElement, maxWaitTime = 10000) => {
  const interval = 50;

  return new Promise((resolve, reject) => {
    const intervalInstance = window.setInterval(() => {
      if (iframe.contentWindow?.document.readyState !== 'loading') {
        clearInterval(intervalInstance);

        return resolve(true);
      }

      maxWaitTime -= interval;

      if (maxWaitTime <= interval) {
        clearInterval(intervalInstance);

        reject();
      }
    }, interval);
  });
};

export const injectCssIntoIframe = (iframe: HTMLIFrameElement, css: string) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

  if (iframeDoc) {
    const style = document.createElement('style');

    style.innerHTML = css;

    iframeDoc.head.appendChild(style);
  }
};

export const updateSearchParams =
  (searchParams: URLSearchParams) => (paramName: string, paramValue?: string | boolean | string[] | null) => {
    if (Array.isArray(paramValue)) {
      searchParams.delete(paramName);

      paramValue.forEach((value) => searchParams.append(paramName, value));

      return searchParams;
    }

    if (paramValue) {
      searchParams.set(paramName, paramValue.toString());

      return searchParams;
    }

    searchParams.delete(paramName);

    return searchParams;
  };

export const isMacOS = () => {
  const userAgent = window.navigator.userAgent;

  return /Mac/.test(userAgent);
};
