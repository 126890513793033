import { memo } from 'react';

import { CaseTimelineContextProviderProps } from './CaseTimelineContext.types';
import { CaseTimelineContext } from './CaseTimelineContext';

const CaseTimelineContextProviderBase = ({
  tab,
  prefix,
  keywords,
  matchWholeWords,
  matchWholePhrase,
  setSearchKeywords,
  eventTypes,
  setEventTypes,
  fileGroups,
  setFileGroups,
  fileTypes,
  setFileTypes,
  isFileTypesFilterPristine,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  confidenceLevels,
  setConfidenceLevels,
  displayOnlyAdditionalEvents,
  setDisplayOnlyAdditionalEvents,
  year,
  setYear,
  allTabSavedOffset,
  bookmarkedTabSavedOffset,
  forceShowEventDetails,
  highlightType,
  isPrinting,
  children,
}: CaseTimelineContextProviderProps) => {
  return (
    <CaseTimelineContext.Provider
      value={{
        tab,
        prefix,
        keywords,
        matchWholeWords,
        matchWholePhrase,
        setSearchKeywords,
        eventTypes,
        setEventTypes,
        fileGroups,
        setFileGroups,
        fileTypes,
        setFileTypes,
        isFileTypesFilterPristine,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        confidenceLevels,
        setConfidenceLevels,
        displayOnlyAdditionalEvents,
        setDisplayOnlyAdditionalEvents,
        year,
        setYear,
        allTabSavedOffset,
        bookmarkedTabSavedOffset,
        highlightType,
        forceShowEventDetails,
        isPrinting,
      }}
    >
      {children}
    </CaseTimelineContext.Provider>
  );
};

export const CaseTimelineContextProvider = memo(CaseTimelineContextProviderBase);
