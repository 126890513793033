import { memo, useMemo } from 'react';
import { Alert, Flex } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { Client } from '@/pageAI/@types';
import { getClientFiles } from '@/pageAI/services/clients';
import { filterUnreadableFileAssets } from '@/pageAI/services/files';
import { singularOrPlural } from '@/shared/utils/string';

interface FileUnreadableQuickAlertProps {
  client: Client;
  isExport?: boolean;
}

const FileUnreadableQuickAlertBase = ({ client, isExport = false }: FileUnreadableQuickAlertProps) => {
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const unreadableFileAssets = useMemo(() => filterUnreadableFileAssets(fileAssets), [fileAssets]);

  if (!unreadableFileAssets.length) return null;

  return (
    <Alert
      mt={4}
      title={
        <Flex align="center" gap={6}>
          <IconAlertTriangle size="1rem" />
          There are unreadable files!
        </Flex>
      }
      color="orange"
      variant="outline"
    >
      Your client contains {unreadableFileAssets.length} unreadable{' '}
      {singularOrPlural('file', 'files')(unreadableFileAssets.length)} that will be excluded from your{' '}
      {isExport ? 'exported file(s)' : 'summaries'}.
    </Alert>
  );
};

export const FileUnreadableQuickAlert = memo(FileUnreadableQuickAlertBase);
