import { useMemo } from 'react';

import { Client } from '@/pageAI/@types';
import { getClientFiles } from '@/pageAI/services/clients';

export const useClientFiles = (client: Client) => {
  const { fileAssets, mainFileCollectionAsset } = useMemo(() => getClientFiles(client), [client]);

  return { fileAssets, fileCollection: mainFileCollectionAsset };
};
