import { memo, useMemo, useRef, useState } from 'react';
import { Box, Flex, Sx, Text } from '@mantine/core';
import { omit } from 'lodash-es';

import {
  canDisplayCodesheetSection,
  convertConditionToTimelineEvents,
  getKeywordsFromMedicalCondition,
  normalizeMedicalConditionFields,
  removeConditionDebugWords,
  shouldAlwaysHideNullFields,
} from '@/pageAI/services/medicalConditions';
import { MedicalConditionItemAsSummaryProps } from './MedicalConditionItemAsSummary.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import {
  isPrintingConditionSummarySelector,
  isReviewingIncrementalChangesSelector,
  showNullFieldsSelector,
  summaryTabSortOrderSelector,
  summaryTabViewModeSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { CaseTimeline } from '../../timeline/CaseTimeline';
import { ConditionSummarySection } from '../summary/ConditionSummarySection';
import { ConditionAllCodesheets } from '../summary/ConditionAllCodesheets';
import { ConditionClaimStatusSection } from '../claimStatus/ConditionClaimStatusSection';
import { ConditionSectionBadges } from '../filters/ConditionSectionBadges';
import { ConditionSectionKey } from '@/pageAI/@types';
import { truthy } from '@/shared/utils/boolean';
import { ClaimStatus } from '@/pageAI/gql/graphql';
import { ConfirmConditionChangesAlert } from '../versionReview/ConfirmConditionChangesAlert';
import { useConditionSummaryDeltas } from '@/pageAI/hooks/conditions/useClientMedicalConditions';

const MedicalConditionItemAsSummaryBase = ({
  medicalCondition: rawMedicalCondition,
}: MedicalConditionItemAsSummaryProps) => {
  const client = useSelectedClient(true);
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);
  const showNullFields = useUnifiedStore(showNullFieldsSelector);
  const sortOrder = useUnifiedStore(summaryTabSortOrderSelector);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);
  const isReviewing = useUnifiedStore(isReviewingIncrementalChangesSelector);

  const { conditionSummaryDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const equivalentDelta = useMemo(
    () =>
      isReviewing
        ? conditionSummaryDeltas?.find((delta) => delta.newCondition.id === rawMedicalCondition.id)
        : undefined,
    [conditionSummaryDeltas, rawMedicalCondition.id, isReviewing],
  );

  const newFileIds = useMemo(() => equivalentDelta?.newFileIds || [], [equivalentDelta]);

  const containerRef = useRef<HTMLDivElement>(null);

  const [chronologicalFields, setChronologicalFields] = useState<ConditionSectionKey[]>([
    'proceduralHistory',
    'medicalEvidence',
    'otherEvidence',
  ]);

  const forceHideNullFields = useMemo(() => shouldAlwaysHideNullFields(rawMedicalCondition), [rawMedicalCondition]);

  const medicalCondition = useMemo(
    () => normalizeMedicalConditionFields(rawMedicalCondition, !forceHideNullFields && showNullFields),
    [rawMedicalCondition, showNullFields, forceHideNullFields],
  );

  const convertedEvents = useMemo(
    () => (viewMode === 'Chronological' ? convertConditionToTimelineEvents(medicalCondition, chronologicalFields) : []),
    [medicalCondition, viewMode, chronologicalFields],
  );

  const keywords = useMemo(
    () => getKeywordsFromMedicalCondition(rawMedicalCondition).map(removeConditionDebugWords),
    [rawMedicalCondition],
  );

  const handleClickSectionBadge = (sectionKey: ConditionSectionKey) => {
    if (viewMode !== 'Table View') return;

    const sectionHeaderElement = containerRef.current?.querySelector(
      `[data-section="${sectionKey}"]`,
    ) as HTMLDivElement | null;

    sectionHeaderElement?.scrollIntoView({
      block: 'start',
    });
  };

  const displayCodesheetSection = canDisplayCodesheetSection(medicalCondition);

  const filteredSectionKeys = useMemo(
    () =>
      [
        medicalCondition.proceduralHistoryEntries || medicalCondition.filingsEntries || displayCodesheetSection
          ? 'proceduralHistory'
          : null,
        medicalCondition.medicalEvidenceEntries ? 'medicalEvidence' : null,
        medicalCondition.otherEvidenceEntries ? 'otherEvidence' : null,
      ].filter(truthy) as ConditionSectionKey[],
    [medicalCondition, displayCodesheetSection],
  );

  const conditionHasClaimStatus =
    medicalCondition.claimStatus?.status && medicalCondition.claimStatus.status !== ClaimStatus.Unknown;

  if (!client) return null;

  const renderBody = () => {
    if (viewMode === 'Chronological')
      return (
        <Box
          sx={{
            ul: {
              paddingLeft: 24,
              lineHeight: 0,
              li: {
                ul: {
                  li: {
                    ul: {
                      li: {
                        ul: {
                          marginBottom: -24,
                        },
                      },
                    },
                  },
                },
              },
            },
            li: {
              lineHeight: '24px',
            },
            '.ghost-Alert-root': {
              marginLeft: -8,
              marginRight: -8,
            },
            '.timeline-groups': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <CaseTimeline
            timeline={convertedEvents}
            virtualized={false}
            sortOrder={sortOrder}
            searchParamsDisabled
            readonly
            isPrinting={isPrinting}
          />
        </Box>
      );

    const sharedHeaderStyle: Sx = isPrinting
      ? {}
      : {
          scrollMarginTop: 16,
          position: 'sticky',
          top: 0,
          paddingTop: 8,
          background: 'white',
          zIndex: 1,
        };

    return (
      <>
        {(medicalCondition.proceduralHistoryEntries || medicalCondition.filingsEntries || displayCodesheetSection) && (
          <Flex direction="column" data-section="proceduralHistory">
            <Flex align="center" justify="space-between" gap="xs" sx={{ ...sharedHeaderStyle }}>
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Procedural History
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.proceduralHistory}
              sectionData={omit(medicalCondition.proceduralHistoryEntries, 'filings')}
              sectionKey="proceduralHistory"
              keywords={keywords}
              additionalSection={
                displayCodesheetSection ? (
                  <Flex
                    sx={(theme) => ({
                      borderBottom: `1px solid ${theme.colors.gray[3]}`,
                      padding: '8px 16px',
                    })}
                  >
                    <Flex>
                      <Text
                        fz="0.875rem"
                        color="dark"
                        sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
                      >
                        Latest Codesheet
                      </Text>
                    </Flex>

                    <ConditionAllCodesheets condition={medicalCondition} />
                  </Flex>
                ) : null
              }
            />

            {medicalCondition.filingsEntries && (
              <Box data-section="filings">
                <Flex
                  sx={(theme) => ({
                    padding: 8,
                    borderLeft: `1px solid ${theme.colors.gray[3]}`,
                    borderRight: `1px solid ${theme.colors.gray[3]}`,
                    ...sharedHeaderStyle,
                  })}
                >
                  <Text fz="0.875rem" fw={600} color="dark">
                    Filings
                  </Text>
                </Flex>

                <ConditionSummarySection
                  medicalCondition={medicalCondition}
                  section={medicalCondition.proceduralHistory?.filings}
                  sectionData={medicalCondition.filingsEntries}
                  sectionKey="proceduralHistory.filings"
                  keywords={keywords}
                />
              </Box>
            )}
          </Flex>
        )}

        {medicalCondition.medicalEvidenceEntries && (
          <Flex direction="column" gap={4} data-section="medicalEvidence">
            <Flex align="center" justify="space-between" gap="xs" sx={{ ...sharedHeaderStyle }}>
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Medical Evidence
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.medicalEvidence}
              sectionData={medicalCondition.medicalEvidenceEntries}
              sectionKey="medicalEvidence"
              keywords={keywords}
            />
          </Flex>
        )}

        {medicalCondition.otherEvidenceEntries && (
          <Flex direction="column" gap={4} data-section="otherEvidence">
            <Flex align="center" sx={{ ...sharedHeaderStyle }}>
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Other Evidence
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.otherEvidence}
              sectionData={medicalCondition.otherEvidenceEntries}
              sectionKey="otherEvidence"
              keywords={keywords}
            />
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" gap="lg" pos="relative" ref={containerRef}>
      {!isPrinting && <ConditionClaimStatusSection condition={medicalCondition} />}

      {!isPrinting && (
        <Flex
          columnGap="xs"
          justify="end"
          rowGap={4}
          sx={{
            flexWrap: 'wrap',
            position: 'sticky',
            top: -4,
            zIndex: 10,
            ...(conditionHasClaimStatus
              ? {
                  marginTop: -40,
                  marginBottom: -44,
                }
              : {
                  marginTop: -24,
                  marginBottom: -44,
                }),
          }}
        >
          <Flex
            sx={(theme) => ({
              padding: '8px 0 0 8px',
              background: theme.white,
              borderTopLeftRadius: 16,
              borderBottomLeftRadius: 16,
            })}
          >
            <ConditionSectionBadges
              onClick={handleClickSectionBadge}
              sections={filteredSectionKeys}
              {...(viewMode === 'Chronological'
                ? {
                    value: chronologicalFields,
                    onChange: (value: string[]) => setChronologicalFields(value as ConditionSectionKey[]),
                  }
                : {})}
            />
          </Flex>
        </Flex>
      )}

      <Box
        sx={(theme) => ({
          ...newFileIds.reduce(
            (acc, newFileId) => ({
              ...acc,
              [`[data-file-id="${newFileId}"]`]: {
                background: theme.fn.lighten(theme.colors.orange[1], 0.2),
                padding: '4px 8px',
                borderRadius: 4,
                fontWeight: 500,
              },
            }),
            {},
          ),
        })}
      >
        {renderBody()}
      </Box>

      <ConfirmConditionChangesAlert />
    </Flex>
  );
};

export const MedicalConditionItemAsSummary = memo(MedicalConditionItemAsSummaryBase);
