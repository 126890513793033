import { memo, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Alert, Box, Flex, Sx, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { CaseTimelineProps } from './CaseTimeline.types';
import {
  getTimelineElementId,
  getYearFromEventDateString,
  groupTimelineEventsByYearAndDate,
  groupTimelineEventsByDate,
  sortCaseTimeline,
} from '@/pageAI/services/caseTimeline';
import { CaseTimelineItemViews } from '@/pageAI/components/summaries/CaseTimelineItemViews';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { useHighlightTimelineEvent, useTimelineKeyboardShortcuts } from './CaseTimeline.utils';
import { useTimelineFilterSearchParams } from '@/pageAI/hooks/caseTimeline/useTimelineFilterSearchParams/useTimelineFilterSearchParams';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { TimelineQuickNavigation } from '../TimelineQuickNavigation';
import { TimelineEventGroup } from '../TimelineEventGroup';
import { sortAlphabetically } from '@/shared/utils/string';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { saveTimelineScrollPosition } from '@/pageAI/states/unified';

const sharedStyle: Sx = {
  p: { margin: 0 },
  li: {
    '.case-timeline-event-badge:first-of-type': {
      marginLeft: 0,
    },
  },
};

const CaseTimelineBase = ({
  timeline,
  sortOrder = 'desc',
  isPrinting = false,
  readonly = false,
  virtualized = true,
  searchParamsDisabled = false,
}: CaseTimelineProps) => {
  const client = useSelectedClient(true);
  const { tab, prefix, allTabSavedOffset, bookmarkedTabSavedOffset } = useCaseTimelineContext();
  useTimelineFilterSearchParams(searchParamsDisabled);

  const virtualizationRef = useRef<VirtuosoHandle>(null);
  const sortedEvents = useMemo(() => sortCaseTimeline(timeline, sortOrder), [timeline, sortOrder]);
  const timelineEventsByYearAndDate = useMemo(() => groupTimelineEventsByYearAndDate(sortedEvents), [sortedEvents]);
  const timelineEventsByDate = useMemo(() => groupTimelineEventsByDate(timeline), [timeline]);
  const timelineEventGroups = useMemo(() => {
    let sorted = Object.entries(timelineEventsByDate).toSorted(([dateA], [dateB]) => {
      return sortAlphabetically(dateA, dateB);
    });

    if (sortOrder === 'desc') sorted = sorted.toReversed();

    // TODO: Refactor this into a reusable function
    return sorted.toSorted(([dateA], [dateB]) => {
      if (dateA === 'N/A') return 1;

      if (dateB === 'N/A') return -1;

      return 0;
    });
  }, [timelineEventsByDate, sortOrder]);

  useEffect(() => {
    if (tab === 'All' && allTabSavedOffset?.current) {
      requestIdleCallback(() => virtualizationRef.current?.scrollTo({ top: allTabSavedOffset.current }));
    } else if (tab === 'Bookmarked' && bookmarkedTabSavedOffset?.current) {
      requestIdleCallback(() => virtualizationRef.current?.scrollTo({ top: bookmarkedTabSavedOffset.current }));
    }
  }, [tab]);

  useEffect(() => {
    virtualizationRef.current?.scrollTo({ top: 0 });
  }, [sortedEvents.length]);

  useTimelineKeyboardShortcuts(sortedEvents);
  useHighlightTimelineEvent(timelineEventGroups, virtualizationRef.current);

  const yearFirstSeenByDate = useMemo(
    () =>
      timelineEventGroups.reduce((acc, [date, events]) => {
        const year = getYearFromEventDateString(date);

        if (!acc[year]) acc[year] = date;

        return acc;
      }, {} as Record<string, string>),
    [timelineEventGroups],
  );

  if (!timeline.length) {
    return (
      <Box px={12}>
        <Alert icon={<IconAlertCircle size="1rem" />} title="No results!" color="dark.3" variant="outline" m="md">
          No events were found for the selected filter(s). Try again with another set of filter.
        </Alert>
      </Box>
    );
  }

  if (!client) return null;

  if (isPrinting) {
    let caseTimelineEventsByYear = Object.entries(timelineEventsByYearAndDate);

    if (sortOrder === 'desc') caseTimelineEventsByYear = caseTimelineEventsByYear.toReversed();

    caseTimelineEventsByYear = caseTimelineEventsByYear.toSorted(([yearA], [yearB]) => {
      if (yearA === 'N/A') return 1;

      if (yearB === 'N/A') return -1;

      return 0;
    });

    return (
      <Flex
        id={getTimelineElementId(client.id, prefix)}
        direction="column"
        gap={virtualized ? 'md' : 4}
        sx={sharedStyle}
      >
        {caseTimelineEventsByYear.map(([year, events]) => {
          return (
            <Flex direction="column" key={year}>
              <Flex align="center" justify={virtualized ? 'center' : 'start'} gap="sm">
                {virtualized && (
                  <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />
                )}

                <Text fz="0.875rem" fw={600} id={year}>
                  {year}
                </Text>

                {virtualized && (
                  <Box
                    sx={(theme) => ({
                      flexGrow: 1,
                      height: 1,
                      border: `1px solid ${theme.colors.gray[5]}`,
                    })}
                  />
                )}
              </Flex>

              {/* TODO: Refactor this component. Use TimelineEventGroup instead */}
              <CaseTimelineItemViews caseTimelineItems={events} />
            </Flex>
          );
        })}
      </Flex>
    );
  }

  const renderTimelineGroups = (date: string, events: CaseTimelineEvent[], groupIndex: number) => {
    const year = getYearFromEventDateString(date);
    const isFirst = yearFirstSeenByDate[year] === date;

    return (
      <Box
        className="timeline-groups"
        key={groupIndex}
        data-index={groupIndex}
        sx={(theme) => ({
          paddingLeft: 32,
          paddingRight: 32,
          del: { background: theme.colors.yellow[3], textDecoration: 'none' },
          '.ghost-Timeline-item:last-of-type': {
            paddingBottom: 24,
          },
          width: '100%',
          ...sharedStyle,
          '.extra-action': {
            opacity: 0,
            pointerEvents: 'none',
            transition: 'opacity 0.1s ease-in-out',
          },
          ...(tab === 'All'
            ? {
                '.extra-action.extra-action--note': { display: 'none' },
              }
            : {
                '.extra-action.extra-action--bookmark': { opacity: 1, pointerEvents: 'all' },
              }),
          ul: {
            paddingLeft: 24,
          },
        })}
      >
        <TimelineEventGroup events={events} date={date} isFirst={isFirst} readonly={readonly} />
      </Box>
    );
  };

  if (virtualized) {
    return (
      <>
        {createPortal(
          <TimelineQuickNavigation timelineByDate={timelineEventsByYearAndDate} sortOrder={sortOrder} />,
          document.getElementById('case-timeline-header-prefix') || document.body,
        )}

        <Virtuoso
          ref={virtualizationRef}
          style={{ height: 'calc(100vh - 116px)', width: '100%', position: 'relative' }}
          data={timelineEventGroups}
          onScroll={(event) => saveTimelineScrollPosition(tab, event.currentTarget.scrollTop)}
          itemContent={(groupIndex, [date, events]) => {
            return renderTimelineGroups(date, events, groupIndex);
          }}
        />
      </>
    );
  }

  return <>{timelineEventGroups.map(([date, events], index) => renderTimelineGroups(date, events, index))}</>;
};

export const CaseTimeline = memo(CaseTimelineBase);
