import { memo, useEffect } from 'react';
import { Flex } from '@mantine/core';
import { IconTemplateOff } from '@tabler/icons-react';

import { selectedSimpleConditionSelector, setDefaultCondition, useUnifiedStore } from '@/pageAI/states/unified';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { truthy } from '@/shared/utils/boolean';
import { SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { DataEmptyPlaceholder } from '../../clients/DataEmptyPlaceholder';
import { ConditionSummaryContainer } from '../../medicalConditions/ConditionSummaryContainer';
import { useConditionSummaryDeltas } from '@/pageAI/hooks/conditions/useClientMedicalConditions';

const UnifiedConditionSummaryBase = () => {
  const client = useSelectedClient(true);

  const {
    medicalConditions,
    isLoading: isLoadingConditions,
    isError: isErrorConditions,
  } = useClientMedicalConditions({
    clientId: client.id,
    autoSelect: true,
  });
  const { isLoading: isLoadingDeltas, isError: isErrorDeltas } = useConditionSummaryDeltas({ clientId: client.id });

  const selectedSimpleCondition = useUnifiedStore(selectedSimpleConditionSelector);

  const isLoading = isLoadingConditions || isLoadingDeltas;
  const isError = isErrorConditions || isErrorDeltas;

  useEffect(() => {
    if (selectedSimpleCondition) return;

    if (!isLoadingConditions && medicalConditions?.length) {
      setDefaultCondition();
    }
  }, [selectedSimpleCondition, isLoadingConditions, medicalConditions]);

  if (client.summaryByConditionStatus !== SummaryByConditionStatus.Generated)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <DataEmptyPlaceholder
          icon={<IconTemplateOff size={80} />}
          title="Not generated"
          description="The condition summary has yet to be generated for this client."
        />
      </Flex>
    );

  if (isLoading) {
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading condition list..."
          description={[
            'Please wait while Page retrieves the list of conditions',
            client ? ` of ${getClientDisplayName(client)}` : '',
            '.',
          ]
            .filter(truthy)
            .join('')}
          fullScreen
        />
      </Flex>
    );
  }

  if (isError || !medicalConditions) throw new Error('Could not load condition summary');

  return <ConditionSummaryContainer />;
};

export const UnifiedConditionSummary = memo(UnifiedConditionSummaryBase);
