import { memo, useMemo } from 'react';
import { Badge, Loader } from '@mantine/core';

import { FileProgressBadgeProps } from './FileProgressBadge.types';
import {
  FileDisplayStatus,
  getFileDisplayStatus,
  getFileOcrMessage,
  isFileProcessing,
  isFileRetriable,
} from '@/shared/services/files';
import { sortFailedFilesPubsub } from '@/pageAI/services/files';

// TODO: Refactor this whole thing: improve performance, make it more readable, etc.
const FileProgressBadgeBase = ({ files = [] }: FileProgressBadgeProps) => {
  const processedFiles = useMemo(() => files.filter((file) => !isFileProcessing(file)), [files]);
  const processingFiles = useMemo(() => files.filter((file) => isFileProcessing(file)), [files]);
  const failedFiles = useMemo(() => files.filter((file) => isFileRetriable(file)), [files]);
  const uploadingFiles = useMemo(
    () => files.filter((file) => getFileDisplayStatus(file) === FileDisplayStatus.UPLOADING),
    [files],
  );

  const isProcessing = processingFiles.length > 0;
  const isUploading = uploadingFiles.length > 0;
  const isFailed = failedFiles.length > 0;
  const isLoading = isProcessing || isUploading;

  const handleClick = () => {
    if (!isFailed) return;

    sortFailedFilesPubsub.publish({});
  };

  if (!isLoading && !isFailed) return null;

  return (
    <Badge
      color={isLoading ? 'orange.7' : 'red'}
      size="md"
      sx={(theme) => ({
        padding: '16px 16px',
        '.ghost-Badge-inner': {
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        },
        ...(isFailed
          ? {
              cursor: 'pointer',
              transition: 'all 0.15s ease-in-out',
              '&:hover': {
                backgroundColor: theme.fn.lighten(theme.colors.red[1], 0.2),
              },
            }
          : {}),
      })}
      data-testid="ocr-progress-badge"
      onClick={handleClick}
    >
      {isLoading && <Loader size={14} color="orange.7" />}

      {isUploading ? (
        <>
          {files.length - uploadingFiles.length - failedFiles.length}/{files.length} uploaded
        </>
      ) : (
        <>
          {isProcessing ? (
            getFileOcrMessage(processedFiles, processingFiles, files)
          ) : (
            <>
              {failedFiles.length}/{files.length} failed
            </>
          )}
        </>
      )}
    </Badge>
  );
};

export const FileProgressBadge = memo(FileProgressBadgeBase);
