import React from 'react';
import { Box } from '@mantine/core';

import { SelectionBoxState } from './SelectionBox.utils';

interface SelectionBoxProps {
  box: SelectionBoxState | undefined;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({ box }) => {
  if (!box) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: `${box.top}px`,
        left: `${box.left}px`,
        width: `${box.width}px`,
        height: `${box.height}px`,
        backgroundColor: 'rgba(0, 120, 255, 0.2)',
        border: '1px solid rgba(0, 120, 255, 0.8)',
        pointerEvents: 'none',
      }}
    />
  );
};

export default SelectionBox;
