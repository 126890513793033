import { useQuery } from '@tanstack/react-query';

import { exportJobsQuery } from '@/pageAI/api/export';
import { ClientExportJobType } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';

interface UseLastExportJobProps {
  clientId: string;
  type: ClientExportJobType;
}

export const queryMap = {
  client: {
    lastExportJob: {
      queryKey: (clientId: string, type: ClientExportJobType) => [clientId, 'last-export-job', type],
      queryFn: (request: GraphQLRequestFunction, clientId: string, type: ClientExportJobType) =>
        request(exportJobsQuery, { id: clientId, type, first: 1 }),
    },
  },
};

export const useLastExportJob = ({ clientId, type }: UseLastExportJobProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading } = useQuery({
    queryKey: queryMap.client.lastExportJob.queryKey(clientId, type),
    queryFn: () => queryMap.client.lastExportJob.queryFn(request, clientId, type),
  });

  const lastExportJob = data?.client?.exportJobs?.nodes?.[0];

  return {
    lastExportJob,
    isLoading,
  };
};
