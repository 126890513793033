import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { updateSearchParams } from '@/shared/lib/browser';
import { formatShortDate } from '@/shared/utils/date';

export const useTimelineFilterSearchParams = (disabled = false) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    keywords,
    matchWholeWords,
    matchWholePhrase,
    eventTypes,
    fileGroups,
    fileTypes,
    fromDate,
    toDate,
    confidenceLevels,
    displayOnlyAdditionalEvents,
  } = useCaseTimelineContext();

  useEffect(() => {
    if (disabled) return;

    const updateSearchParamsFn = updateSearchParams(searchParams);

    const fromDateStr = fromDate ? formatShortDate(fromDate) : undefined;
    const toDateStr = toDate ? formatShortDate(toDate) : undefined;

    updateSearchParamsFn('keywords', keywords.join(' '));
    updateSearchParamsFn('wholeWords', keywords.length > 0 ? matchWholeWords : undefined);
    updateSearchParamsFn('wholePhrase', keywords.length > 0 ? matchWholePhrase : undefined);
    updateSearchParamsFn('eventType', eventTypes);
    updateSearchParamsFn('fileGroup', fileGroups);
    updateSearchParamsFn('fromDate', fromDateStr);
    updateSearchParamsFn('toDate', toDateStr);
    updateSearchParamsFn('fileType', fileGroups?.length ? [] : fileTypes);
    updateSearchParamsFn('confidenceLevel', confidenceLevels);
    updateSearchParamsFn('displayOnlyAdditionalEvents', displayOnlyAdditionalEvents);

    setSearchParams(searchParams, { replace: true });
  }, [
    disabled,
    searchParams,
    setSearchParams,
    keywords,
    matchWholeWords,
    matchWholePhrase,
    eventTypes,
    fileGroups,
    fileTypes,
    fromDate,
    toDate,
    confidenceLevels,
    displayOnlyAdditionalEvents,
  ]);
};
