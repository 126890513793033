import { memo } from 'react';
import { Alert, Anchor, Flex, Text, ThemeIcon } from '@mantine/core';
import { IconDownload, IconPdf } from '@tabler/icons-react';

import { Client } from '@/pageAI/@types';
import { ClientExportJobStatus, ClientExportJobType } from '@/pageAI/gql/graphql';
import { useLastExportJob } from '@/pageAI/hooks/export/useLastExportJob';
import { formatDateTime, parseDateOnly } from '@/shared/utils/date';
import {
  getClientMergedCFileName,
  getExportedConditionSummaryFileName,
  getExportedTimelineFileName,
} from '@/pageAI/services/clients';

interface LastExportJobProps {
  client: Client;
  type: ClientExportJobType;
}

const LastExportJobBase = ({ client, type }: LastExportJobProps) => {
  const { lastExportJob, isLoading } = useLastExportJob({
    clientId: client.id,
    type,
  });

  if (isLoading || !lastExportJob) return null;

  const exportTypeText = type === ClientExportJobType.ConditionSummary ? 'condition summary' : 'timeline';

  const renderDescription = () => {
    if (lastExportJob.status === ClientExportJobStatus.Running) {
      return (
        <Text fz="0.875rem" color="dark">
          The {exportTypeText} for this client is being exported. The export started at{' '}
          <Text fw={500} component="span">
            {formatDateTime(lastExportJob.createdAt)}
          </Text>
          .
        </Text>
      );
    }

    if (lastExportJob.status === ClientExportJobStatus.Failed) {
      return (
        <Text fz="0.875rem" color="red.6">
          The {exportTypeText} for this client failed to export. The export started at{' '}
          <Text fw={500} component="span">
            {formatDateTime(lastExportJob.createdAt)}
          </Text>
          .
        </Text>
      );
    }

    return (
      <Text fz="0.875rem" color="dark">
        The {exportTypeText} for this client was last exported at{' '}
        <Text fw={500} component="span">
          {formatDateTime(lastExportJob.createdAt)}
        </Text>
        .
      </Text>
    );
  };

  return (
    <Alert
      title={
        <Flex gap={6} align="center">
          <IconDownload size={16} />
          Exported files
        </Flex>
      }
      variant="outline"
    >
      <Flex direction="column">
        {renderDescription()}

        {lastExportJob.cFileDownloadUrl && (
          <Flex align="center" gap={6} mt={4}>
            <ThemeIcon color="red.6" size="xs">
              <IconPdf size={16} />
            </ThemeIcon>

            <Anchor color="blue" fw={500} href={lastExportJob.cFileDownloadUrl} target="_blank">
              {getClientMergedCFileName(client, parseDateOnly(lastExportJob.createdAt))}
            </Anchor>
          </Flex>
        )}

        {lastExportJob.downloadUrl && (
          <Flex align="center" gap={6} mb={4}>
            <ThemeIcon color="red.6" size="xs">
              <IconPdf size={16} />
            </ThemeIcon>

            <Anchor color="blue" fw={500} href={lastExportJob.downloadUrl} target="_blank">
              {type === ClientExportJobType.ConditionSummary
                ? getExportedConditionSummaryFileName(client, '.pdf', parseDateOnly(lastExportJob.createdAt))
                : getExportedTimelineFileName(client, '.pdf', parseDateOnly(lastExportJob.createdAt))}
            </Anchor>
          </Flex>
        )}
      </Flex>
    </Alert>
  );
};

export const LastExportJob = memo(LastExportJobBase);
