import { memo, useCallback, useState } from 'react';
import { Box, Flex } from '@mantine/core';

import { PrintableCaseTimeline } from '../../PrintableCaseTimeline';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { Client } from '@/pageAI/@types';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { SortOrder } from '@/shared/@types';
import { useCFileOrder } from '@/pageAI/hooks/export/useCFileOrder';

export interface ExportTimelineLayoutProps {
  client: Client;
  timeline: CaseTimelineEvent[];
  showBookmarkSigns?: boolean;
  showEventDetails?: boolean;
  tab?: CaseTimelineTab;
  sortOrder?: SortOrder;
  cFileIds?: string[];
}

const ExportTimelineLayoutBase = ({
  client,
  timeline,
  showBookmarkSigns = false,
  showEventDetails = false,
  tab = 'All',
  sortOrder,
  cFileIds,
}: ExportTimelineLayoutProps) => {
  const [isExporting, setIsExporting] = useState(true);

  useCFileOrder(client, cFileIds);

  const handleFinish = useCallback(() => {
    setIsExporting(false);
  }, []);

  return (
    <Box
      className="printable"
      sx={{
        background: 'white',
        minHeight: '100vh',
        height: 'fit-content',
        ul: {
          paddingLeft: 24,
        },
        ...(showBookmarkSigns
          ? {
              '.extra-action.extra-action--bookmark': {
                display: 'none',
                '&.extra-action.extra-action--bookmarked': {
                  display: 'block',
                },
              },
            }
          : {
              '.extra-action.extra-action--bookmark': {
                display: 'none',
              },
            }),
      }}
    >
      <PrintableCaseTimeline
        client={client}
        caseTimeline={timeline}
        tab={tab}
        forceShowEventDetails={showEventDetails}
        sortOrder={sortOrder}
        onFinish={handleFinish}
      />

      {isExporting && (
        <Flex
          align="center"
          justify="center"
          sx={(theme) => ({ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, background: theme.white })}
        >
          <LoadingPlaceholder
            title="Exporting timeline..."
            description="Please wait while your timeline is being exported..."
          />
        </Flex>
      )}
    </Box>
  );
};

export const ExportTimelineLayout = memo(ExportTimelineLayoutBase);
