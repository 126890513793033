export interface NetworkError {
  response: {
    error: string;
    status: number;
    headers: Record<string, unknown>;
  };
}

export const isNetworkError = (error: unknown): error is NetworkError => {
  return !!(error as NetworkError).response;
};
